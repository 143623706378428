import React, { useState } from "react";
import { fabric } from "fabric";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import { Steps } from "pages/ai-mock-up-generator/context";
import SettingsIcon from "@material-ui/icons/Settings";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import moment from "moment";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "actions/loader";
import Select from "components/Select";
import TextField from "components/TextField";
import Slider from "@material-ui/core/Slider";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import TextPlus from "assets/images/text-plus.png";
import TextMinus from "assets/images/text-minus.png";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import styled from "styled-components";
import {
  Rotate,
  LayersCustomize,
  AlignIcons,
  AlignIcons1,
} from "styles/components/ArtWork/browseLibrary";
import { getDTGPlusFromProductId } from "selectors/products";
import Button from "components/Button";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import KeyboardTabIcon from "@material-ui/icons/KeyboardTab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatStrikethroughIcon from "@material-ui/icons/FormatStrikethrough";
import { HexColorPicker } from "react-colorful";
import { ROUTES } from "shared/constants/routes";
import { SHOW_CART_DRAWER } from "actions/cart";
import { Typography } from "@material-ui/core";

const ImageThumbnailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;
  background: #f5f5f5;
  border-radius: 4px;
  margin: 8px 0;
`;

const ImageThumbnail = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  border: 2px solid ${(props) => (props.isActive ? "#4CAF50" : "transparent")};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: #fff;
  }
`;

const onloadImageProcess = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

function loadImageWithRetry(url, callback) {
  fabric.Image.fromURL(
    url,
    function (image) {
      if (image) {
        // Image loaded successfully, pass it to the callback
        callback(image);
      }
    },
    function () {
      loadImageWithRetry(url, callback); // Retry after the delay
    }
  );
}

const LayersPopoverOptions = ({
  canvas,
  onApply,
  orderLine,
  activeObjectIndex,
  // setCanvasJSON,
  canvasJSON,
  state,
  setState,
  options,
  setOption,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const [maskIndex, setMaskIndex] = useState(null);
  const products = useSelector((state) => state?.products?.data);
  const [dtgValues, setDtgOptions] = useState({});
  const dtgPlusOptions = getDTGPlusFromProductId(products, orderLine?.ID);
  const canvasActiveObject = canvasJSON?.objects[activeObjectIndex];
  const imageObjects = canvasJSON?.objects?.filter((o) => o?.type === "image");
  const [fill, setFill] = useState(null);
  const [allignment, setAllignment] = useState(null);
  const [imageEffect, setImageEffect] = useState(null);
  const [rotateSkew, setRotateSkew] = useState(null);
  const [editText, setEditText] = useState(null);
  const [textColor, setTextColor] = useState(null);
  const [fillColor, setFillColor] = useState(null);

  const cart = useSelector((state) => state?.cart);

  const googleFonts = useSelector((state) => state?.googleFonts?.data);
  const fonts = googleFonts?.items?.filter(
    (d) => d?.version === "v15" || d?.version === "v14"
  );

  // if (activeObjectIndex < 0) return null;

  return (
    <LayersCustomize>
      <AlignIcons>
        <AlignIcons1>
          {(canvasActiveObject?.type === "i-text" ||
            canvasActiveObject?.type === "group") && (
            <>
              <Button
                id="edit-text"
                className="action-button"
                title="Edit Text"
                onClick={() => setEditText(true)}
              />

              <div
                style={{
                  display: "flex",
                  height: "50px",
                }}
              >
                <div
                  style={{
                    height: "50px",
                    background: "#3f4652",
                    color: "#fff",
                    alignItems: "center",
                    display: "flex",
                    padding: "0 6px",
                    fontSize: "12px",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                >
                  FONT SIZE
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid #3f4652",
                    paddingLeft: "4px",
                    height: "50px",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <img
                    style={{ width: "30px", cursor: "pointer" }}
                    src={TextPlus}
                    alt=""
                    onClick={() => {
                      if (canvasActiveObject?.type === "group") {
                        const obj = canvas.getActiveObject();
                        const groupObjects = obj.getObjects();
                        groupObjects.forEach((g) =>
                          g.set("fontSize", obj.fontSize + 2)
                        );
                        canvas.requestRenderAll();
                        canvas.fire("object:modified", { target: obj });
                      } else {
                        const obj = canvas.getActiveObject();
                        obj.set("fontSize", obj.fontSize + 2);
                        canvas.requestRenderAll();
                        canvas.fire("object:modified", { target: obj });
                      }
                    }}
                  />
                  <img
                    style={{ width: "30px", cursor: "pointer" }}
                    src={TextMinus}
                    alt=""
                    onClick={() => {
                      if (canvasActiveObject?.type === "group") {
                        const obj = canvas.getActiveObject();
                        const groupObjects = obj.getObjects();
                        groupObjects.forEach((g) =>
                          g.set(
                            "fontSize",
                            obj.fontSize - 2 > 14
                              ? obj.fontSize - 2
                              : obj.fontSize
                          )
                        );
                        canvas.requestRenderAll();
                        canvas.fire("object:modified", { target: obj });
                      } else {
                        const obj = canvas.getActiveObject();
                        obj.set(
                          "fontSize",
                          obj.fontSize - 2 > 14
                            ? obj.fontSize - 2
                            : obj.fontSize
                        );
                        canvas.requestRenderAll();
                        canvas.fire("object:modified", { target: obj });
                      }
                    }}
                  />
                </div>
              </div>

              <Button
                id="text-color"
                className="action-button"
                title="Text Color"
                onClick={() => setTextColor(true)}
              />
            </>
          )}

          {canvasActiveObject?.type === "image" && (
            <Button
              className="action-button"
              title="Remove Backgroud"
              onClick={async () => {
                dispatch(showLoader());

                const isBackgroundRemoved = canvasActiveObject?.src?.includes(
                  "e_background_removal"
                );

                const secureUrlSplit = isBackgroundRemoved
                  ? canvasActiveObject?.src?.split(
                      "/upload/e_background_removal/"
                    )
                  : canvasActiveObject?.src?.split("/upload/");

                const url = `${secureUrlSplit[0]}/upload/e_background_removal/${secureUrlSplit[1]}`;

                const image = new Image();

                image.onload = () => {
                  const data = {
                    ...canvasJSON,
                    objects: canvasJSON.objects.map((item, j) => {
                      if (j !== activeObjectIndex) {
                        return item;
                      }

                      return { ...item, src: url };
                    }),
                  };

                  // setCanvasJSON(data);
                  // onApply(data);

                  const obj = canvas.getActiveObject();

                  obj.setSrc(
                    url,
                    function () {
                      canvas.requestRenderAll();
                      canvas.fire("object:modified", { target: obj });
                      dispatch(hideLoader());
                    },
                    { crossOrigin: "annonymous" }
                  );
                };

                image.onerror = () => {
                  dispatch(showLoader());
                  image.src = `${url}?timestamp=${moment().unix()}`;
                };

                image.src = url;
              }}
            />
          )}

          <Button
            id="allignment"
            className="action-button"
            title="Allign"
            onClick={() => setAllignment(true)}
          />

          {imageObjects?.length > 0 &&
            canvasActiveObject?.type === "i-text" && (
              <Button
                className="action-button"
                id={`StarBorderOutlined${activeObjectIndex}`}
                title="Mask Text"
                onClick={() => setMaskIndex(activeObjectIndex)}
              />
            )}

          {canvasActiveObject?.type === "image" && (
            <Tooltip title="Fill" style={{ display: "none" }}>
              <FormatColorFillIcon
                id={`fill${activeObjectIndex}`}
                onClick={() => {
                  setFill(activeObjectIndex);
                }}
              />
            </Tooltip>
          )}

          {canvasActiveObject?.type === "image" &&
            dtgPlusOptions?.length > 0 && (
              <Button
                id="image-effect"
                className="action-button"
                title="Image Effect"
                onClick={() => setImageEffect(true)}
              />
            )}

          <Button
            id="rotate-skew"
            className="action-button"
            title="Rotate Skew"
            onClick={() => setRotateSkew(true)}
          />
        </AlignIcons1>

        <AlignIcons1 style={{ justifyContent: "flex-end" }}>
          <Button
            className="action-button1"
            title="Change Product"
            onClick={() => setState({ ...state, step: Steps.Products })}
          />
          <Button
            className="action-button1"
            title="AI Design Studio"
            onClick={() => setState({ ...state, step: Steps.AIDesginStudio })}
          />
          <Button
            className="action-button1"
            title="Mock-up Product"
            onClick={() => setOption(options.mocks)}
          />
          <Button
            className="action-button1"
            title="Mocks Library"
            onClick={() => setOption(options.image)}
          />
          <Button className="action-button1" title="Order" />

          <Badge
            badgeContent={cart?.count}
            showZero
            color="secondary"
            style={{ marginRight: "10px" }}
            onClick={() => {
              dispatch({ type: SHOW_CART_DRAWER });
            }}
          >
            <ShoppingCartIcon style={{ color: "#0009", cursor: "pointer" }} />
          </Badge>

          <SettingsIcon
            onClick={() => history.push(ROUTES.ACCOUNT_SETTINGS.LINK)}
          />
        </AlignIcons1>
      </AlignIcons>

      <Popover
        open={textColor}
        anchorEl={document.getElementById("text-color")}
        onClose={() => setTextColor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            padding: 15,
            display: "flex",
            alignItems: "center",
            gap: 20,
            cursor: "pointer",
          }}
        >
          <Rotate>
            <HexColorPicker
              style={{ width: 300 }}
              color={
                typeof canvasActiveObject?.fill === "string"
                  ? canvasActiveObject?.fill
                  : "#000000"
              }
              onChange={(color) => {
                if (canvasActiveObject?.type === "group") {
                  const obj = canvas.getActiveObject();
                  const groupObjects = obj.getObjects();
                  groupObjects.forEach((g) => g.set("fill", color));
                  canvas.requestRenderAll();
                  canvas.fire("object:modified", { target: obj });
                } else {
                  const obj = canvas.getActiveObject();
                  obj.set({ fill: color });
                  canvas.requestRenderAll();
                  canvas.fire("object:modified", { target: obj });
                }
              }}
            />

            <TextField
              size="small"
              // label={`Enter Color Hex Code`}

              value={canvasActiveObject?.fill || ""}
              onChange={(color) => {
                if (color?.length <= 7) {
                  if (canvasActiveObject?.type === "group") {
                    const obj = canvas.getActiveObject();
                    const groupObjects = obj.getObjects();
                    groupObjects.forEach((g) =>
                      g.set("fill", `#${color?.replace(/#/g, "")}`)
                    );
                    canvas.requestRenderAll();
                    canvas.fire("object:modified", { target: obj });
                  } else {
                    const obj = canvas.getActiveObject();
                    obj.set({ fill: `#${color?.replace(/#/g, "")}` });
                    canvas.requestRenderAll();
                    canvas.fire("object:modified", { target: obj });
                  }
                }

                // setValue({
                //   ...value,
                //   fill: `#${color?.replace(/#/g, "")}`,
                // });
              }}
            />
          </Rotate>
        </div>
      </Popover>

      <Popover
        open={editText}
        anchorEl={document.getElementById("edit-text")}
        onClose={() => setEditText(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            padding: "30px 15px 15px",
            display: "flex",
            alignItems: "center",
            gap: 20,
            cursor: "pointer",
            flexDirection: "column",
          }}
        >
          <TextField
            label="Edit Text"
            // label={`Enter Text (${
            //   canvasActiveObject?.text?.length
            //     ? canvasActiveObject?.text?.length
            //     : 0
            // }/20)`}
            value={
              canvasActiveObject?.type === "group"
                ? canvasActiveObject?.objects?.map((t) => t?.text)?.join("")
                : canvasActiveObject?.text || ""
            }
            onChange={(v) => {
              // if (v?.length <= 20) {
              //   setValue({
              //     ...value,
              //     text: v,
              //   });
              // }

              if (v?.length > 0) {
                if (canvasActiveObject?.type === "group") {
                  if (canvasActiveObject.shadow?.color === "circular") {
                    const text = v;
                    const centerX = 113;
                    const centerY = 151;
                    const radius = 70;
                    const startAngle = -90;
                    const angleStep = 360 / text.length; // Angle step per character
                    const radianOffset = (startAngle * Math.PI) / 180; // Convert start angle to radians
                    const characters = [];

                    for (let i = 0; i < text.length; i++) {
                      const char = text[i];
                      const angle =
                        radianOffset + (angleStep * i * Math.PI) / 180;

                      // Calculate character position along the arc
                      const x = centerX + radius * Math.cos(angle);
                      const y = centerY + radius * Math.sin(angle);

                      // Calculate character rotation angle
                      const charAngle = (angle * 180) / Math.PI + 90;

                      // Create individual character as fabric.Text
                      const charText = new fabric.IText(char, {
                        left: x,
                        top: y,
                        fontSize: 20,
                        fill: "rgb(0,0,0)",
                        originX: "center",
                        originY: "center",
                        angle: charAngle,
                      });

                      characters.push(charText);
                    }

                    const group = new fabric.Group(characters, {
                      left: 100,
                      top: 120,
                      originX: "center",
                      originY: "center",
                      shadow: "circular",
                    });

                    canvas.remove(canvas.getObjects()[activeObjectIndex]);

                    canvas.insertAt(group, activeObjectIndex);

                    canvas.renderAll();
                  } else if (canvasActiveObject.shadow?.color === "curved") {
                    const angleStep = 180 / v.length; // Step between each character angle
                    const letters = [];

                    for (let i = 0; i < v.length; i++) {
                      const char = v[i];
                      // Calculate the angle and position for each character
                      const angle = 180 + i * angleStep;
                      const x = 80 * Math.cos((angle * Math.PI) / 180);
                      const y = 80 * Math.sin((angle * Math.PI) / 180);
                      // Create individual IText for each character to allow for editing
                      const letter = new fabric.IText(char, {
                        left: x,
                        top: y,
                        fontSize: 20,
                        originX: "center",
                        originY: "center",
                        fill: canvasActiveObject.objects[0]?.fill,
                        fontWeight: canvasActiveObject.objects[0]?.fontWeight,
                        fontFamily: canvasActiveObject.objects[0]?.fontFamily,
                        linethrough: canvasActiveObject.objects[0]?.linethrough,
                        underline: canvasActiveObject.objects[0]?.underline,
                        overline: canvasActiveObject.objects[0]?.overline,
                        fontStyle: canvasActiveObject.objects[0]?.fontStyle,
                      });
                      // Rotate each character to follow the curve
                      letter.angle = angle + 90;
                      // Add each character to the canvas
                      letters.push(letter);
                    }
                    const group = new fabric.Group(letters, {
                      left: canvasActiveObject?.left,
                      top: canvasActiveObject?.top,
                      right: canvasActiveObject?.right,
                      originX: "center",
                      originY: "center",
                      curved: true,
                      shadow: "curved",
                    });

                    canvas.remove(canvas.getObjects()[activeObjectIndex]);

                    canvas.insertAt(group, activeObjectIndex);

                    canvas.renderAll();
                  } else if (canvasActiveObject.shadow?.color === "oblique") {
                    const letters = [];

                    for (let i = 0; i < v.length; i++) {
                      const char = v[i];

                      const totalLength = v.length;

                      // Define min and max font sizes to ensure visibility
                      const minFontSize = 22; // Minimum font size to ensure readability
                      const maxFontSize = 48; // Maximum font size for the first letter

                      // Calculate font size with a smoother, more controlled progression
                      const fontSizeProgress = 1 - i / (totalLength - 1);
                      const fontSize = Math.round(
                        minFontSize +
                          (maxFontSize - minFontSize) * fontSizeProgress
                      );

                      let width = 0;

                      for (let k = 0; k < letters.length; k++) {
                        width += Math.round(letters[k].width);
                      }

                      const x = width;

                      // Create individual IText for each character to allow for editing
                      const letter = new fabric.IText(char, {
                        left: x,
                        top: 0,
                        fontSize,
                        originX: "center",
                        originY: "center",
                        fontFamily: "B612 Mono",
                      });

                      letters.push(letter);
                    }

                    const group = new fabric.Group(letters, {
                      left: 70,
                      top: 40,
                      originX: "center",
                      originY: "center",
                      shadow: "oblique",
                    });

                    canvas.remove(canvas.getObjects()[activeObjectIndex]);

                    canvas.insertAt(group, activeObjectIndex);

                    canvas.renderAll();
                  }
                } else {
                  const obj = canvas.getActiveObject();
                  obj.set("text", v);
                  canvas.requestRenderAll();
                  // const json = canvas?.toJSON();
                  // setCanvasJSON(json);
                  // onApply(json);
                  canvas.fire("object:modified", { target: obj });
                }
              }
            }}
          />

          <Autocomplete
            style={{ width: "100%" }}
            options={fonts.map((f) => f?.family)}
            value={
              canvasActiveObject?.objects?.length > 0
                ? canvasActiveObject?.objects[0]?.fontFamily
                : canvasActiveObject?.fontFamily
            }
            // inputValue={inputValue}
            disableClearable
            // onInputChange={(event, newInputValue) => {
            //   setInputValue(newInputValue);
            // }}
            renderOption={(option) => (
              <div style={{ fontFamily: option }}>{option}</div>
            )}
            onChange={(event, newValue) => {
              if (canvasActiveObject?.type === "group") {
                const obj = canvas.getActiveObject();
                const groupObjects = obj.getObjects();
                groupObjects.forEach((g) => g.set("fontFamily", newValue));
                canvas.requestRenderAll();
                // const json = canvas?.toJSON();
                // setCanvasJSON(json);
                // onApply(json);
                canvas.fire("object:modified", { target: obj });
              } else {
                const data = {
                  ...canvasJSON,
                  objects: canvasJSON.objects.map((item, j) => {
                    if (j !== activeObjectIndex) {
                      return item;
                    }

                    return { ...item, fontFamily: newValue };
                  }),
                };

                // setCanvasJSON(data);
                // onApply(data);

                const obj = canvas.getActiveObject();
                obj.set({ fontFamily: newValue });
                canvas.requestRenderAll();
                // const json = canvas?.toJSON();
                // setCanvasJSON(json);
                // onApply(data);
                canvas.fire("object:modified", { target: obj });
              }
            }}
            renderInput={(params) => (
              <TextField label="Font Family" {...params} />
            )}
          />

          <div
            style={{
              padding: 15,
              display: "flex",
              alignItems: "center",
              gap: 20,
              cursor: "pointer",
            }}
          >
            <Tooltip title="Bold">
              <FormatBoldIcon
                onClick={() => {
                  if (canvasActiveObject?.type === "group") {
                    const obj = canvas.getActiveObject();
                    const groupObjects = obj.getObjects();
                    groupObjects.forEach((g) =>
                      g.set(
                        "fontWeight",
                        g?.fontWeight === "bold" ? "normal" : "bold"
                      )
                    );
                    canvas.requestRenderAll();
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.fire("object:modified", { target: obj });
                  } else {
                    const data = {
                      ...canvasJSON,
                      objects: canvasJSON.objects.map((item, j) => {
                        if (j !== activeObjectIndex) {
                          return item;
                        }

                        return {
                          ...item,
                          fontWeight:
                            item?.fontWeight === "bold" ? "normal" : "bold",
                        };
                      }),
                    };

                    // setCanvasJSON(data);
                    // onApply(data);

                    const obj = canvas.getActiveObject();
                    obj.set({
                      fontWeight:
                        obj?.fontWeight === "bold" ? "normal" : "bold",
                    });
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.requestRenderAll();
                    canvas.fire("object:modified", { target: obj });
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Italic">
              <FormatItalicIcon
                onClick={() => {
                  if (canvasActiveObject?.type === "group") {
                    const obj = canvas.getActiveObject();
                    const groupObjects = obj.getObjects();
                    groupObjects.forEach((g) =>
                      g.set(
                        "fontStyle",
                        g?.fontStyle === "italic" ? "normal" : "italic"
                      )
                    );
                    canvas.requestRenderAll();
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.fire("object:modified", { target: obj });
                  } else {
                    // const data = {
                    //   ...canvasJSON,
                    //   objects: canvasJSON.objects.map((item, j) => {
                    //     if (j !== activeObjectIndex) {
                    //       return item;
                    //     }

                    //     return {
                    //       ...item,
                    //       fontStyle:
                    //         item?.fontStyle === "italic"
                    //           ? "normal"
                    //           : "italic",
                    //     };
                    //   }),
                    // };

                    // setCanvasJSON(data);
                    // onApply(data);

                    const obj = canvas.getActiveObject();
                    obj.set({
                      fontStyle:
                        obj?.fontStyle === "italic" ? "normal" : "italic",
                    });
                    canvas.requestRenderAll();
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.fire("object:modified", { target: obj });
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Underline">
              <FormatUnderlinedIcon
                onClick={() => {
                  if (canvasActiveObject?.type === "group") {
                    const obj = canvas.getActiveObject();
                    const groupObjects = obj.getObjects();
                    groupObjects.forEach((g) =>
                      g.set("underline", !g?.underline)
                    );
                    canvas.requestRenderAll();
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.fire("object:modified", { target: obj });
                  } else {
                    const data = {
                      ...canvasJSON,
                      objects: canvasJSON.objects.map((item, j) => {
                        if (j !== activeObjectIndex) {
                          return item;
                        }

                        return {
                          ...item,
                          underline: !item?.underline,
                        };
                      }),
                    };

                    // setCanvasJSON(data);
                    // onApply(data);

                    const obj = canvas.getActiveObject();
                    obj.set({
                      underline: !obj?.underline,
                    });
                    canvas.requestRenderAll();
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.fire("object:modified", { target: obj });
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Strike through">
              <FormatStrikethroughIcon
                onClick={() => {
                  if (canvasActiveObject?.type === "group") {
                    const obj = canvas.getActiveObject();
                    const groupObjects = obj.getObjects();
                    groupObjects.forEach((g) =>
                      g.set("overline", !g?.overline)
                    );
                    canvas.requestRenderAll();
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.fire("object:modified", { target: obj });
                  } else {
                    const data = {
                      ...canvasJSON,
                      objects: canvasJSON.objects.map((item, j) => {
                        if (j !== activeObjectIndex) {
                          return item;
                        }

                        return {
                          ...item,
                          overline: !item?.overline,
                        };
                      }),
                    };

                    // setCanvasJSON(data);
                    // onApply(data);

                    const obj = canvas.getActiveObject();
                    obj.set({
                      overline: !obj?.overline,
                    });
                    canvas.requestRenderAll();
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.fire("object:modified", { target: obj });
                  }
                }}
              />
            </Tooltip>
          </div>
        </div>
      </Popover>

      <Popover
        open={rotateSkew}
        anchorEl={document.getElementById("rotate-skew")}
        onClose={() => setRotateSkew(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            padding: 15,
            display: "flex",
            width: "500px",
            alignItems: "center",
            gap: 20,
            cursor: "pointer",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: 15,
              display: "flex",

              width: "100%",
              alignItems: "center",
              gap: 20,
            }}
          >
            <h4
              style={{
                margin: "0 0 5px 0",
                width: 80,
              }}
            >
              Rotate
            </h4>
            <Rotate>
              <div style={{ width: 300 }}>
                <Slider
                  defaultValue={10}
                  min={-180}
                  max={180}
                  value={canvasActiveObject?.angle}
                  onChange={(e, v) => {
                    const obj = canvas.getActiveObject();
                    obj.rotate(v);
                    canvas.requestRenderAll();
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.fire("object:modified", { target: obj });
                  }}
                />
              </div>

              <TextField
                label="Angle"
                type="number"
                size="small"
                value={canvasActiveObject?.angle}
                onChange={(angle) => {
                  const obj = canvas.getActiveObject();
                  obj.rotate(Number(angle));
                  canvas.requestRenderAll();
                  // const json = canvas?.toJSON();
                  // setCanvasJSON(json);
                  // onApply(json);
                  canvas.fire("object:modified", { target: obj });
                }}
              />
            </Rotate>
          </div>

          <div
            style={{
              marginTop: 15,
              display: "flex",

              width: "100%",
              alignItems: "center",
              gap: 20,
            }}
          >
            <h4
              style={{
                margin: "0 0 5px 0",
                width: 80,
              }}
            >
              Skew X
            </h4>
            <Rotate>
              <div style={{ width: 300 }}>
                <Slider
                  defaultValue={10}
                  min={-30}
                  max={30}
                  value={canvasActiveObject?.skewX}
                  onChange={(e, v) => {
                    const obj = canvas.getActiveObject();
                    obj.set({ skewX: Number(v) });
                    canvas.requestRenderAll();
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.fire("object:modified", { target: obj });
                  }}
                />
              </div>

              <TextField
                label="Skew X"
                type="number"
                size="small"
                value={canvasActiveObject?.skewX}
                onChange={(angle) => {
                  const obj = canvas.getActiveObject();
                  obj.set({ skewX: Number(angle) });
                  canvas.requestRenderAll();
                  // const json = canvas?.toJSON();
                  // setCanvasJSON(json);
                  // onApply(json);
                  canvas.fire("object:modified", { target: obj });
                }}
              />
            </Rotate>
          </div>

          <div
            style={{
              marginTop: 15,
              display: "flex",

              width: "100%",
              alignItems: "center",
              gap: 20,
            }}
          >
            <h4
              style={{
                margin: "0 0 5px 0",
                width: 80,
              }}
            >
              Skew Y
            </h4>
            <Rotate>
              <div style={{ width: 300 }}>
                <Slider
                  defaultValue={10}
                  min={-30}
                  max={30}
                  value={canvasActiveObject?.skewY}
                  onChange={(e, v) => {
                    const obj = canvas.getActiveObject();
                    obj.set({ skewY: Number(v) });
                    canvas.requestRenderAll();
                    // const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    // onApply(json);
                    canvas.fire("object:modified", { target: obj });
                  }}
                />
              </div>

              <TextField
                label="Skew Y"
                type="number"
                size="small"
                value={canvasActiveObject?.skewY}
                onChange={(angle) => {
                  const obj = canvas.getActiveObject();
                  obj.set({ skewY: Number(angle) });
                  canvas.requestRenderAll();
                  // const json = canvas?.toJSON();
                  // setCanvasJSON(json);
                  // onApply(json);
                  canvas.fire("object:modified", { target: obj });
                }}
              />
            </Rotate>
          </div>
        </div>
      </Popover>

      <Popover
        open={imageEffect}
        anchorEl={document.getElementById("image-effect")}
        onClose={() => setImageEffect(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            padding: 15,
            width: "500px",
          }}
        >
          <div
            style={{
              marginTop: 15,
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: 20,
            }}
          >
            <h4 style={{ width: 80 }}>Effects</h4>

            <div style={{ flex: 1 }}>
              <Select
                options={dtgPlusOptions}
                label="Select Effect"
                value={
                  dtgValues[`dtg_${activeObjectIndex}`] || {
                    DisplayName: "",
                  }
                }
                valueKey="DisplayName"
                labelKey="DisplayName"
                onChange={(dtgProcessName, e) => {
                  setDtgOptions({
                    ...dtgValues,
                    [`dtg_${activeObjectIndex}`]: {
                      DisplayName: dtgProcessName,
                    },
                  });
                }}
              />
            </div>

            {dtgPlusOptions?.find(
              (d) =>
                d?.Name === dtgValues[`dtg_${activeObjectIndex}`]?.DisplayName
            )?.Colors?.length > 0 && (
              <div style={{ flex: 1 }}>
                {dtgPlusOptions
                  ?.find(
                    (d) =>
                      d?.Name ===
                      dtgValues[`dtg_${activeObjectIndex}`]?.DisplayName
                  )
                  ?.Colors?.find(
                    (c) =>
                      c?.ColorName ===
                      dtgValues[`color_${activeObjectIndex}`]?.DisplayName
                  ) && (
                  <img
                    alt="color"
                    src={
                      dtgPlusOptions
                        ?.find(
                          (d) =>
                            d?.Name ===
                            dtgValues[`dtg_${activeObjectIndex}`]?.DisplayName
                        )
                        ?.Colors?.find(
                          (c) =>
                            c?.ColorName ===
                            dtgValues[`color_${activeObjectIndex}`]?.ColorName
                        )?.ColorImagePath
                    }
                  />
                )}
                <Select
                  options={
                    dtgPlusOptions?.find(
                      (d) =>
                        d?.Name ===
                        dtgValues[`dtg_${activeObjectIndex}`]?.DisplayName
                    )?.Colors
                  }
                  label="Effect Color"
                  value={
                    dtgValues[`color_${activeObjectIndex}`] || {
                      ColorName: "",
                    }
                  }
                  valueKey="ColorName"
                  labelKey="ColorName"
                  onChange={async (dtgColorName) => {
                    setDtgOptions({
                      ...dtgValues,
                      [`color_${activeObjectIndex}`]: {
                        ColorName: dtgColorName,
                      },
                    });

                    const selectedColor = dtgPlusOptions
                      ?.find(
                        (d) =>
                          d?.Name ===
                          dtgValues[`dtg_${activeObjectIndex}`]?.DisplayName
                      )
                      ?.Colors?.find((c) => c?.ColorName === dtgColorName);

                    const canvas1 = document.createElement("canvas");

                    const ctx = canvas1.getContext("2d");

                    const secureUrlSplit =
                      canvasActiveObject?.src?.split("/upload/");

                    let secureUrl = "";

                    if (!!secureUrlSplit?.length) {
                      const isBackgroundRemoval =
                        canvasActiveObject?.src?.includes(
                          "e_background_removal"
                        );

                      if (isBackgroundRemoval) {
                        secureUrl = `${secureUrlSplit[0]}/upload/e_background_removal/e_colorize,co_rgb:ffffff/${secureUrlSplit[1]}`;
                      } else {
                        secureUrl = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:ffffff/${secureUrlSplit[1]}`;
                      }
                    }

                    dispatch(showLoader());

                    const img1 = await onloadImageProcess(secureUrl);

                    const s = selectedColor?.ColorImagePath?.split("/upload/");

                    const img2 = await onloadImageProcess(
                      `${s[0]}/upload/w_${img1?.width},h_${img1?.height}/${s[1]}`
                    );

                    canvas1.width = img1.width;
                    canvas1.height = img1.height;

                    ctx.fillStyle = ctx.createPattern(img2, "no-repeat");
                    // fill canvas with pattern
                    ctx.fillRect(0, 0, canvas1.width, canvas1.height);
                    // use blending mode multiply
                    ctx.globalCompositeOperation = "multiply";
                    // draw sofa on top
                    ctx.drawImage(img1, 0, 0, img1.width, img1.height);
                    // change composition mode
                    ctx.globalCompositeOperation = "destination-in";
                    // draw to cut-out sofa
                    ctx.drawImage(
                      img1,
                      0,
                      0,
                      img1.width,
                      img1.height
                      // img1.width * 0.5,
                      // img1.height * 0.5
                    );

                    const dataURL = canvas1.toDataURL("image/png");

                    const fileData = new FormData();
                    fileData.append("file", dataURL);
                    fileData.append("upload_preset", "tnedst8q");
                    fileData.append(
                      "folder",
                      `Shirtly/${emailid}/PlusArtFiles`
                    );

                    const data = await fetch(
                      "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload",
                      {
                        method: "post",
                        body: fileData,
                      }
                    );

                    const json = await data?.json();

                    const obj = canvas.getActiveObject();

                    obj.setSrc(
                      json?.secure_url,
                      function () {
                        canvas.requestRenderAll();
                        canvas.fire("object:modified", { target: obj });
                        dispatch(hideLoader());
                      },
                      { crossOrigin: "annonymous" }
                    );
                  }}
                />
              </div>
            )}
          </div>

          <div
            style={{
              marginTop: 15,
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: 20,
            }}
          >
            <h4 style={{ width: 80 }}>Mask Image</h4>

            <div style={{ flex: 1 }}>
              <ImageThumbnailsContainer>
                {canvasJSON?.objects
                  ?.filter(
                    (obj) => obj.type === "image" && obj !== canvasActiveObject
                  )
                  ?.map((imageObj, index) => (
                    <Tooltip
                      key={`image-${index}`}
                      title={imageObj.src.split("/").pop()}
                    >
                      <ImageThumbnail
                        isActive={
                          canvas.getActiveObject() ===
                          canvas
                            .getObjects()
                            .find((obj) => obj.src === imageObj.src)
                        }
                        onClick={async () => {
                          const canvas1 = document.createElement("canvas");

                          const ctx = canvas1.getContext("2d");

                          const secureUrlSplit =
                            canvasActiveObject?.src?.split("/upload/");

                          let secureUrl = "";

                          if (!!secureUrlSplit?.length) {
                            const isBackgroundRemoval =
                              canvasActiveObject?.src?.includes(
                                "e_background_removal"
                              );

                            if (isBackgroundRemoval) {
                              secureUrl = `${secureUrlSplit[0]}/upload/e_background_removal/e_colorize,co_rgb:ffffff/${secureUrlSplit[1]}`;
                            } else {
                              secureUrl = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:ffffff/${secureUrlSplit[1]}`;
                            }
                          }

                          dispatch(showLoader());

                          const img1 = await onloadImageProcess(secureUrl);

                          const s = imageObj.src?.split("/upload/");

                          const img2 = await onloadImageProcess(
                            `${s[0]}/upload/w_${img1?.width},h_${img1?.height}/${s[1]}`
                          );

                          canvas1.width = img1.width;
                          canvas1.height = img1.height;

                          ctx.fillStyle = ctx.createPattern(img2, "no-repeat");
                          // fill canvas with pattern
                          ctx.fillRect(0, 0, canvas1.width, canvas1.height);
                          // use blending mode multiply
                          ctx.globalCompositeOperation = "multiply";
                          // draw sofa on top
                          ctx.drawImage(img1, 0, 0, img1.width, img1.height);
                          // change composition mode
                          ctx.globalCompositeOperation = "destination-in";
                          // draw to cut-out sofa
                          ctx.drawImage(
                            img1,
                            0,
                            0,
                            img1.width,
                            img1.height
                            // img1.width * 0.5,
                            // img1.height * 0.5
                          );

                          const dataURL = canvas1.toDataURL("image/png");

                          const fileData = new FormData();
                          fileData.append("file", dataURL);
                          fileData.append("upload_preset", "tnedst8q");
                          fileData.append(
                            "folder",
                            `Shirtly/${emailid}/PlusArtFiles`
                          );

                          const data = await fetch(
                            "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload",
                            {
                              method: "post",
                              body: fileData,
                            }
                          );

                          const json = await data?.json();

                          const obj = canvas.getActiveObject();

                          obj.setSrc(
                            json?.secure_url,
                            function () {
                              canvas.requestRenderAll();
                              canvas.fire("object:modified", { target: obj });
                              dispatch(hideLoader());
                            },
                            { crossOrigin: "annonymous" }
                          );
                        }}
                      >
                        <img src={imageObj.src} alt={`Canvas ${index + 1}`} />
                      </ImageThumbnail>
                    </Tooltip>
                  ))}
                {canvasJSON?.objects?.filter((obj) => obj.type === "image")
                  .length === 0 && (
                  <Typography
                    variant="body2"
                    style={{ color: "#666", padding: "8px" }}
                  >
                    No images in canvas
                  </Typography>
                )}
              </ImageThumbnailsContainer>
            </div>
          </div>

          {/* dd */}

          <Rotate style={{ marginTop: 20 }}>
            <h4 style={{ width: 113 }}>Color</h4>
            <HexColorPicker
              style={{ width: 300 }}
              color={fillColor || "#000000"}
              onChangeCapture={() => {}}
              onChange={(color) => {
                setFillColor(color);
              }}
            />

            <div>
              <TextField
                size="small"
                label="Fill Color Code"
                value={fillColor || ""}
                onChange={(color) => {
                  if (color?.length <= 7) {
                    setFillColor(color);
                  }
                }}
              />

              {fillColor?.startsWith("#") && fillColor?.length === 7 && (
                <Button
                  title="Apply Color"
                  style={{
                    marginTop: 20,
                    width: "100%",
                    background: "#000",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    dispatch(showLoader());
                    const src = canvasActiveObject?.src;
                    const isEColorize = src?.includes("e_colorize");

                    const secureUrlSplit = isEColorize
                      ? src?.split("/upload/e_colorize:50,co_rgb:")
                      : src?.split("/upload/");

                    const obj = canvas.getActiveObject();

                    if (!isEColorize) {
                      obj.setSrc(
                        `${
                          secureUrlSplit[0]
                        }/upload/e_colorize:50,co_rgb:${fillColor.substring(
                          1
                        )}/${secureUrlSplit[1]}`,
                        function () {
                          canvas.requestRenderAll();
                          canvas.fire("object:modified", { target: obj });
                          dispatch(hideLoader());
                        },
                        { crossOrigin: "annonymous" }
                      );
                    } else {
                      obj.setSrc(
                        `${
                          secureUrlSplit[0]
                        }/upload/e_colorize:50,co_rgb:${fillColor.substring(
                          1
                        )}/${secureUrlSplit[1]?.substring(7)}`,
                        function () {
                          canvas.requestRenderAll();
                          canvas.fire("object:modified", { target: obj });
                          dispatch(hideLoader());
                        },
                        { crossOrigin: "annonymous" }
                      );
                    }
                  }}
                />
              )}
            </div>
          </Rotate>
        </div>
      </Popover>

      <Popover
        open={allignment}
        anchorEl={document.getElementById("allignment")}
        onClose={() => setAllignment(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            padding: 15,
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: 20,
            cursor: "pointer",
          }}
        >
          <Tooltip title="Horizontal Align">
            <FormatAlignCenterIcon
              onClick={() => {
                const obj = canvas.getActiveObject();
                obj.centerH();
                // const json = canvas?.toJSON();
                // setCanvasJSON(json);
                // onApply(json);
                canvas.fire("object:modified", { target: obj });
              }}
            />
          </Tooltip>
          <Tooltip title="Vertical Align">
            <VerticalAlignCenterIcon
              onClick={() => {
                const obj = canvas.getActiveObject();
                obj.centerV();
                // const json = canvas?.toJSON();
                // setCanvasJSON(json);
                // onApply(json);
                canvas.fire("object:modified", { target: obj });
              }}
            />
          </Tooltip>
          <Tooltip title="Align Top">
            <VerticalAlignTopIcon
              onClick={() => {
                const obj = canvas.getActiveObject();
                obj.set({ top: 0 });
                canvas.requestRenderAll();
                // const json = canvas?.toJSON();
                // setCanvasJSON(json);
                // onApply(json);
                canvas.fire("object:modified", { target: obj });
              }}
            />
          </Tooltip>
          <Tooltip title="Align Bottom">
            <VerticalAlignBottomIcon
              onClick={() => {
                const activeObject = canvas.getActiveObject();
                activeObject.set({
                  top:
                    canvas?.height - activeObject.height * activeObject.scaleY,
                });
                canvas.requestRenderAll();
                // const json = canvas?.toJSON();
                // setCanvasJSON(json);
                // onApply(json);
                canvas.fire("object:modified", {
                  target: activeObject,
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Align Right">
            <KeyboardTabIcon
              onClick={() => {
                const activeObject = canvas.getActiveObject();
                activeObject.set({
                  left: canvas.width - activeObject.width * activeObject.scaleX,
                });
                canvas.requestRenderAll();
                // const json = canvas?.toJSON();
                // setCanvasJSON(json);
                // onApply(json);
                canvas.fire("object:modified", {
                  target: activeObject,
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Align Left">
            <ArrowBackIcon
              onClick={() => {
                const activeObject = canvas.getActiveObject();
                activeObject.set({
                  left: 0,
                });
                canvas.requestRenderAll();
                // const json = canvas?.toJSON();
                // setCanvasJSON(json);
                // onApply(json);
                canvas.fire("object:modified", {
                  target: activeObject,
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Flip Vertical">
            <SyncAltIcon
              onClick={() => {
                const activeObject = canvas.getActiveObject();
                activeObject.toggle("flipX");
                canvas.requestRenderAll();
                // const json = canvas?.toJSON();
                // setCanvasJSON(json);
                // onApply(json);
                canvas.fire("object:modified", {
                  target: activeObject,
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Flip Horizontal">
            <ImportExportIcon
              onClick={() => {
                const activeObject = canvas.getActiveObject();
                activeObject.toggle("flipY");
                canvas.requestRenderAll();
                // const json = canvas?.toJSON();
                // setCanvasJSON(json);
                // onApply(json);
                canvas.fire("object:modified", {
                  target: activeObject,
                });
              }}
            />
          </Tooltip>
        </div>
      </Popover>

      <Popover
        // id={id}
        open={fill !== null}
        anchorEl={document.getElementById(`fill${activeObjectIndex}`)}
        onClose={() => setFill(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            padding: 15,
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: 20,
          }}
        >
          <Rotate>
            <HexColorPicker
              style={{ width: 300 }}
              color={
                typeof canvasActiveObject?.fill === "string"
                  ? canvasActiveObject?.fill
                  : "#000000"
              }
              onChange={(color) => {
                if (color === "#NaNNaNNaN") {
                  return;
                }

                const data1 = {
                  ...canvasJSON,
                  objects: canvasJSON.objects.map((item, j) => {
                    if (j !== fill) {
                      return { ...item };
                    }

                    const isEColorize = item?.src?.includes("e_colorize");

                    const split = isEColorize
                      ? item?.src?.split("/upload/e_colorize,co_rgb:")
                      : item?.src?.split("/upload/");

                    if (!isEColorize) {
                      return {
                        ...item,
                        src: `${split[0]}/upload/e_colorize,co_rgb:${
                          color?.split("#")[1]
                        }/${split[1]}`,
                        fill: color,
                      };
                    } else {
                      return {
                        ...item,
                        src: `${split[0]}/upload/e_colorize,co_rgb:${
                          color?.split("#")[1]
                        }/${split[1]?.substring(7)}`,
                        fill: color,
                      };
                    }
                  }),
                };

                // setCanvasJSON(data1);
                // onApply(data1);
              }}
            />

            <TextField
              size="small"
              disabled
              label="Must Enter Color Hex Code"
              value={canvasActiveObject?.fill || ""}
              onChange={(color) => {
                // if (color?.length <= 7) {
                //   const obj = canvas.getActiveObject();
                //   obj.set({ fill: `#${color?.replace(/#/g, "")}` });
                //   canvas.requestRenderAll();
                //   const json = canvas?.toJSON();
                //   setCanvasJSON(json);
                //   canvas.fire("object:modified", { target: obj });
                // }
              }}
            />
          </Rotate>
          {/* </HexColorPickerWrapper> */}
        </div>
      </Popover>

      <Popover
        // id={id}
        open={maskIndex !== null}
        anchorEl={document.getElementById(
          `StarBorderOutlined${activeObjectIndex}`
        )}
        onClose={() => setMaskIndex(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <h4 style={{ margin: "4px 6px" }}>Select Image to Mask</h4>
        <div
          style={{
            display: "flex",
            padding: "0 10px 10px",
            gap: 4,
          }}
        >
          {imageObjects?.map((q) => (
            <img
              src={q?.src}
              key={q?.src}
              alt=""
              width="50"
              onClick={async () => {
                const s = q?.src?.split("/upload/");

                dispatch(showLoader());

                loadImageWithRetry(
                  `${s[0]}/upload/w_${Math.floor(
                    canvasActiveObject?.width
                  )},h_${Math.floor(canvasActiveObject?.height)}/${s[1]}`,
                  (img) => {
                    dispatch(hideLoader());
                    const activeObject = canvas.getActiveObject();

                    // img.set({
                    //   height: activeObject?.height,
                    //   width: activeObject?.width,
                    // });

                    const imgElement = img.getElement();

                    const pattern = new fabric.Pattern({
                      source: imgElement,
                      repeat: "repeat",
                    });

                    activeObject.set({
                      fill: pattern,
                    });
                    canvas.requestRenderAll();
                    const json = canvas?.toJSON();
                    // setCanvasJSON(json);
                    onApply(json);
                    canvas.fire("object:modified", {
                      target: activeObject,
                    });
                  }
                );

                // fabric.Image.fromURL(
                //   `${s[0]}/upload/w_${Math.floor(canvasActiveObject?.width)}/${
                //     s[1]
                //   }`,
                //   function (img) {
                //     // Create the pattern from the image
                //     const pattern = new fabric.Pattern({
                //       source: img.getElement(),
                //       repeat: "repeat",
                //     });
                //     const activeObject = canvas.getActiveObject();
                //     activeObject.set({
                //       fill: pattern,
                //     });
                //     canvas.requestRenderAll();
                //     const json = canvas?.toJSON();
                //     setCanvasJSON(json);
                //     canvas.fire("object:modified", {
                //       target: activeObject,
                //     });
                //   }
                // );
              }}
              style={{
                border: "1px dashed #000",
                cursor: "pointer",
                padding: 2,
                borderRadius: 4,
              }}
            />
          ))}
        </div>
      </Popover>
    </LayersCustomize>
  );
};

export default LayersPopoverOptions;
