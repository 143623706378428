import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import { Tooltip } from "@material-ui/core";

const SizeTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 8px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 8px;
  background: #f5f5f5;
  font-weight: 600;
`;

const TableCell = styled.td`
  padding: 8px;
  border-bottom: 1px solid #eee;
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  border-bottom: 1px solid #eee;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 800px;
    max-width: 90vw;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 24px;
`;

const DetailSection = styled.div`
  margin-bottom: 24px;
`;

const OrderDetailsModal = ({ open, onClose, order }) => {
  if (!order) return null;

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogHeader>
        <DialogTitle style={{ padding: 0 }}>Order Details</DialogTitle>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <StyledDialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DetailSection>
              <Typography variant="h6" gutterBottom>
                Order Information
              </Typography>
              <Typography>Order ID: {order.orderNumber}</Typography>
              <Typography>Status: {order.status}</Typography>
              <Typography>
                Date: {moment.unix(order.createdAt).format("MM/DD/YYYY")}
              </Typography>
            </DetailSection>
          </Grid>

          <Grid item xs={12} md={6}>
            <DetailSection>
              <Typography variant="h6" gutterBottom>
                Customer Information
              </Typography>
              <Typography>Name: {order?.shippingAddress?.name}</Typography>
              <Typography>Email: {order?.user}</Typography>
            </DetailSection>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ margin: "16px 0" }} />
            <DetailSection>
              <Typography variant="h6" gutterBottom>
                Order Items
              </Typography>
              {order.orderedItems?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "8px",
                    border: "1px dashed #ccc",
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item?.colors[0]?.mockImagesResponse?.thumbImage}
                      style={{ width: "100px", height: "100px" }}
                    />
                    <Typography>
                      Product: {item?.product?.Product?.Name}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      margin: "10px 0",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "#000" }}>Colors:</div>
                    {item?.colors?.map((c) => (
                      <Tooltip title={c?.Name} key={`Colors${c.ID}`}>
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            border: `1px solid ${c?.HexCode}`,
                            borderRadius: "12px",
                            paddingRight: "6px",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              background: c?.HexCode,
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                            }}
                          ></div>
                          <div>{c?.Name}</div>
                        </div>
                      </Tooltip>
                    ))}
                  </div>

                  {item?.product?.Sizes?.filter((s) => s?.qty > 0)?.length >
                    0 && (
                    <SizeTable>
                      <thead>
                        <tr>
                          <TableHeader>Size</TableHeader>
                          <TableHeader>Quantity</TableHeader>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.product?.Sizes?.filter((s) => s?.qty > 0)?.map(
                          (p) => (
                            <tr key={p?.Name}>
                              <TableCell>{p?.Name}</TableCell>
                              <TableCell>{p?.qty}</TableCell>
                            </tr>
                          )
                        )}
                      </tbody>
                    </SizeTable>
                  )}
                </div>
              ))}
            </DetailSection>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ margin: "16px 0" }} />
            <DetailSection>
              <Typography variant="h6" gutterBottom>
                Shipping Information
              </Typography>
              <Typography>
                Address: {order.shippingAddress?.address}&nbsp;
                {order.shippingAddress?.address2}
              </Typography>
              <Typography>City: {order.shippingAddress?.city}</Typography>
              <Typography>State: {order.shippingAddress?.state}</Typography>
              <Typography>ZIP: {order.shippingAddress?.postal_code}</Typography>
              <Typography>Country: {order.shippingAddress?.country}</Typography>
            </DetailSection>
          </Grid>
        </Grid>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default OrderDetailsModal;
