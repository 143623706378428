import { useState } from "react";
import _ from "lodash";
import { saveAs } from "file-saver";
import Tooltip from "@material-ui/core/Tooltip";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LayersPopoverOptions from "components/ArtWork/LayersPopoverOptions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AIGeneratedImages from "components/ArtWork/AIGeneratedImages";
import Model from "pages/customizer-tool/Model";
import ImageIcon from "@material-ui/icons/Image";
import Button from "components/Button";
import PaletteIcon from "@material-ui/icons/Palette";
import LayersIcon from "@material-ui/icons/Layers";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { default as ImageComponent } from "components/Image";
import { Steps } from "pages/ai-mock-up-generator/context";
import FileDownloadIcon from "@material-ui/icons/CloudDownload";
import ThreeSixtyIcon from "@material-ui/icons/ThreeSixty";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import BrowseTextDrawer from "components/ArtWork/BrowseTextDrawer";
import BrowseLibrary from "components/ArtWork/NewBrowseLibrary";
import LayersDrawer from "components/ArtWork/LayersDrawer";
import LogoGeneration from "components/ArtWork/LogoGeneration";
import ProductTab from "components/ArtWork/ProductTab";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { showLoader, hideLoader } from "actions/loader";
import { getCustomGraphics } from "actions/designer";
import {
  Container,
  Header,
  HeaderOption,
  Body,
} from "styles/components/ArtWork/artwork-options";
import {
  Header as PHeader,
  Title,
  ProductColorWrapper,
  GeneratedMockups,
} from "styles/pages/my-stores/products/productCreator";
import COLORS from "shared/constants/colors";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import MockLibrary from "components/ArtWork/MocksLibrary";
import Orders from "pages/orders";

// const options = {
//   product: 6,
//   text: 1,
//   image: 2,
//   layers: 4,
//   logo: 5,
//   threeDTool: 8,
//   aiImages: 9,
//   mocks: 10,
// };

const ArtworkOptions = ({
  orderLine,
  plus,
  canvas,
  onFabricDrop,
  setOrderLine,
  state,
  setState,
  printType,
  onDrop,
  mocks,
  options,
  option,
  setOption,
  product,
}) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const token = useSelector((state) => state?.auth?.profile?.token);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const [option, setOption] = useState(options.product);

  const handleOnDrop = async (artwork) => {
    let secureUrl = artwork?.secure_url;
    let secureUrlSplit = secureUrl.split("/upload/");
    const API_ENDPOINT =
      "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";

    if (secureUrlSplit?.length === 1) {
      dispatch(showLoader());
      const fileData = new FormData();
      fileData.append("file", secureUrl);
      fileData.append("upload_preset", "tnedst8q");
      fileData.append(
        "folder",
        `Shirtly/${emailid}/CustomGraphics/${artwork?.user}`
      );
      const data = await fetch(API_ENDPOINT, {
        method: "post",
        body: fileData,
      });
      const tempArtwork = await data?.json();

      artwork = { ...artwork, ...tempArtwork };
      dispatch(hideLoader());
    }

    artwork.type = "image";

    new fabric.Image.fromURL(
      artwork?.secure_url,
      function (img) {
        img.set({
          top: 10,
          left: 10,
          lockAspectRatio: true,
          ...artwork,
        });
        const { width: artworkWidth, height: artworkHeight } = artwork;
        const canvasWidth = canvas?.width;
        const canvasHeight = canvas?.height;
        const largeDimension =
          artworkWidth > artworkHeight
            ? "width"
            : artworkWidth < artworkHeight
            ? "height"
            : "width";
        if (artworkHeight > canvasHeight && largeDimension === "height") {
          img.scaleToHeight(canvasHeight - 45);
        }
        if (artworkWidth > canvasWidth && largeDimension === "width") {
          img.scaleToWidth(canvasWidth - 30);
        }
        canvas.add(img);
      },
      {
        crossOrigin: "anonymous",
      }
    );
    onDrop(artwork);
  };

  return (
    <Container>
      <Header>
        <div style={{ background: "#3f4652" }}>
          <Tooltip
            title={<h1>Select Product or Color</h1>}
            arrow
            placement="right"
          >
            <HeaderOption
              id="products"
              onClick={() => setOption(options.product)}
              selected={option === options.product}
              style={{
                borderBottomRightRadius: option === options.image ? 5 : 0,
              }}
            >
              <LocalOfferIcon />
              <div className="title">PRODUCTS</div>
            </HeaderOption>
          </Tooltip>

          <Tooltip title={<h1>Add Images</h1>} arrow placement="right">
            <HeaderOption
              id="add-image"
              selected={option === options.image}
              onClick={() => setOption(options.image)}
              style={{
                borderTopRightRadius: option === options.product ? 5 : 0,
                borderBottomRightRadius: option === options.text ? 5 : 0,
              }}
            >
              <ImageIcon />
              <div className="title">IMAGES</div>
            </HeaderOption>
          </Tooltip>

          {/* <HeaderOption
            id="ai-images"
            selected={option === options.aiImages}
            onClick={() => setOption(options.aiImages)}
            style={{
              borderTopRightRadius: option === options.image ? 5 : 0,
              borderBottomRightRadius: option === options.logo ? 5 : 0,
            }}
          >
            <ImageIcon />
            <div className="title">AI IMAGES</div>
          </HeaderOption> */}

          {/* <HeaderOption
            id="ai-images"
            selected={option === options.logo}
            onClick={() => setOption(options.logo)}
            style={{
              borderTopRightRadius: option === options.image ? 5 : 0,
              borderBottomRightRadius: option === options.text ? 5 : 0,
            }}
          >
            <AcUnitIcon />
            <div className="title">AI LOGO</div>
          </HeaderOption> */}

          <Tooltip title={<h1>Add Text</h1>} arrow placement="right">
            <HeaderOption
              id="add-text"
              style={{
                borderTopRightRadius: option === options.image ? 5 : 0,
                borderBottomRightRadius: option === options.layers ? 5 : 0,
              }}
              onClick={() => setOption(options.text)}
              selected={option === options.text}
            >
              <TextFieldsIcon />
              <div className="title">TEXT</div>
            </HeaderOption>
          </Tooltip>

          <Tooltip title={<h1>Adjust Layers</h1>} arrow placement="right">
            <HeaderOption
              id="add-layers"
              selected={option === options.layers}
              onClick={() => setOption(options.layers)}
              style={{
                borderTopRightRadius: option === options.text ? 5 : 0,
                borderBottomRightRadius: option === options.mocks ? 5 : 0,
              }}
            >
              <LayersIcon />
              <div className="title">LAYERS</div>
            </HeaderOption>
          </Tooltip>

          <Tooltip
            title={<h1>View generated mocks</h1>}
            arrow
            placement="right"
          >
            <HeaderOption
              id="templates"
              selected={option === options.mocks}
              onClick={() => setOption(options.mocks)}
              style={{
                borderTopRightRadius: option === options.layers ? 5 : 0,
                borderBottomRightRadius: option === options.threeDTool ? 5 : 0,
              }}
            >
              <LayersIcon />
              <div className="title">MOCKS</div>
            </HeaderOption>
          </Tooltip>

          <Tooltip title={<h1>Change Product</h1>} arrow placement="right">
            <HeaderOption
              id="change-product"
              onClick={() => setState({ ...state, step: Steps.Products })}
            >
              <LayersIcon />
              <div className="title">Change Product</div>
            </HeaderOption>
          </Tooltip>

          <Tooltip title={<h1>AI Design Studio</h1>} arrow placement="right">
            <HeaderOption
              id="ai-design-studio"
              onClick={() => setState({ ...state, step: Steps.AIDesginStudio })}
              style={{
                // borderTopRightRadius: option === options.layers ? 5 : 0,
                borderBottomRightRadius:
                  option === options.mocksLibrary ? 5 : 0,
              }}
            >
              <LayersIcon />
              <div className="title">AI Design Studio</div>
            </HeaderOption>
          </Tooltip>

          <Tooltip title={<h1>Mocks Library</h1>} arrow placement="right">
            <HeaderOption
              id="mocks-library"
              selected={option === options.mocksLibrary}
              onClick={() => setOption(options.mocksLibrary)}
            >
              <LayersIcon />
              <div className="title">
                Mocks
                <br />
                Library
              </div>
            </HeaderOption>
          </Tooltip>

          <Tooltip title={<h1>Orders</h1>} arrow placement="right">
            <HeaderOption
              id="Orders"
              onClick={() => setOption(options.orders)}
              style={{
                borderTopRightRadius: option === options.mocksLibrary ? 5 : 0,
                // borderBottomRightRadius: option === options.threeDTool ? 5 : 0,
              }}
            >
              <LayersIcon />
              <div className="title">Orders</div>
            </HeaderOption>
          </Tooltip>

          <Tooltip title={<h1>Help</h1>} arrow placement="right">
            <HeaderOption id="help">
              <LayersIcon />
              <div className="title">Help</div>
            </HeaderOption>
          </Tooltip>
        </div>
      </Header>

      <Body>
        {canvas?.toJSON()?.objects?.length > 0 && (
          <LayersPopoverOptions
            canvas={canvas}
            onApply={(json) => {
              canvas.clear();
              canvas.loadFromJSON(json);
            }}
            state={state}
            setState={setState}
            orderLine={orderLine}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            handleClick={handleClick}
            handleClose={handleClose}
            activeObjectIndex={canvas
              ?.getObjects()
              ?.indexOf(canvas.getActiveObject())}
            canvasJSON={canvas?.toJSON()}
            options={options}
            option={option}
            setOption={setOption}
          />
        )}

        {option === options.product && (
          <ProductTab
            state={state}
            setState={setState}
            product={product}
            orderLine={orderLine}
            setOrderLine={setOrderLine}
          />
        )}

        {option === options.text && (
          <BrowseTextDrawer
            orderLine={orderLine}
            plus={plus}
            onDrop={async (artwork) => {
              if (artwork?.circular) {
                const text = artwork?.text;
                const centerX = 113;
                const centerY = 151;
                const radius = 70;
                const startAngle = -90;
                const angleStep = 360 / text.length; // Angle step per character
                const radianOffset = (startAngle * Math.PI) / 180; // Convert start angle to radians
                const characters = [];

                for (let i = 0; i < text.length; i++) {
                  const char = text[i];
                  const angle = radianOffset + (angleStep * i * Math.PI) / 180;

                  // Calculate character position along the arc
                  const x = centerX + radius * Math.cos(angle);
                  const y = centerY + radius * Math.sin(angle);

                  // Calculate character rotation angle
                  const charAngle = (angle * 180) / Math.PI + 90;

                  // Create individual character as fabric.Text
                  const charText = new fabric.IText(char, {
                    left: x,
                    top: y,
                    fontSize: 20,
                    fill: artwork?.color,
                    fontWeight: artwork?.fontBold ? "bold" : "normal",
                    fontFamily: artwork?.fontFamily,
                    linethrough: artwork?.fontStrikeThrough,
                    underline: artwork?.fontUnderline,
                    overline: artwork?.fontOverline,
                    fontStyle: artwork?.fontItalic ? "italic" : "normal",
                    originX: "center",
                    originY: "center",
                    angle: charAngle,
                  });

                  characters.push(charText);
                }

                const group = new fabric.Group(characters, {
                  left: 100,
                  top: 120,
                  originX: "center",
                  originY: "center",
                  shadow: "circular",
                });

                canvas.add(group);
              } else if (artwork?.textNumber) {
                const text = new fabric.IText("10", {
                  fontFamily: "Bebas Neue",
                  fontSize: 120,
                  color: "rgb(0,0,0)",
                  left: 42,
                  top: 42,
                });

                const text1 = new fabric.IText("Messi", {
                  fontSize: 30,
                  color: "rgb(0,0,0)",
                  fontFamily: "Barlow Semi Condensed",
                  left: 62,
                  top: 155,
                });

                canvas.add(text);
                canvas.add(text1);
              } else if (artwork?.curved) {
                const angleStep = 180 / artwork?.text.length; // Step between each character angle
                const letters = [];

                for (let i = 0; i < artwork?.text.length; i++) {
                  const char = artwork?.text[i];

                  // Calculate the angle and position for each character
                  const angle = 180 + i * angleStep;
                  const x = 80 * Math.cos((angle * Math.PI) / 180);
                  const y = 80 * Math.sin((angle * Math.PI) / 180);

                  // Create individual IText for each character to allow for editing
                  const letter = new fabric.IText(char, {
                    left: x,
                    top: y,
                    fontSize: 20,
                    originX: "center",
                    originY: "center",
                    fill: artwork?.color,
                    fontWeight: artwork?.fontBold ? "bold" : "normal",
                    fontFamily: artwork?.fontFamily,
                    linethrough: artwork?.fontStrikeThrough,
                    underline: artwork?.fontUnderline,
                    overline: artwork?.fontOverline,
                    fontStyle: artwork?.fontItalic ? "italic" : "normal",
                  });

                  // Rotate each character to follow the curve
                  letter.angle = angle + 90;

                  // Add each character to the canvas
                  letters.push(letter);
                }

                const group = new fabric.Group(letters, {
                  left: 94,
                  top: 80,
                  right: 0,
                  originX: "center",
                  originY: "center",
                  shadow: "curved",
                });

                canvas.add(group);
              } else if (artwork?.oblique) {
                const letters = [];

                for (let i = 0; i < artwork?.text.length; i++) {
                  const char = artwork?.text[i];

                  const totalLength = artwork?.text.length;

                  // Define min and max font sizes to ensure visibility
                  const minFontSize = 22; // Minimum font size to ensure readability
                  const maxFontSize = 48; // Maximum font size for the first letter

                  // Calculate font size with a smoother, more controlled progression
                  const fontSizeProgress = 1 - i / (totalLength - 1);
                  const fontSize = Math.round(
                    minFontSize + (maxFontSize - minFontSize) * fontSizeProgress
                  );

                  let width = 0;

                  for (let k = 0; k < letters.length; k++) {
                    width += Math.round(letters[k].width);
                  }

                  const x = width;

                  // Create individual IText for each character to allow for editing
                  const letter = new fabric.IText(char, {
                    left: x,
                    top: 0,
                    fontSize,
                    originX: "center",
                    originY: "center",
                    fill: artwork?.color,
                    fontWeight: artwork?.fontBold ? "bold" : "normal",
                    fontFamily: "B612 Mono",
                    linethrough: artwork?.fontStrikeThrough,
                    underline: artwork?.fontUnderline,
                    overline: artwork?.fontOverline,
                  });

                  letters.push(letter);
                }

                const group = new fabric.Group(letters, {
                  left: 70,
                  top: 40,
                  originX: "center",
                  originY: "center",
                  shadow: "oblique",
                });

                canvas.add(group);
              } else if (artwork?.text) {
                const text = new fabric.IText(artwork?.text, {
                  fontWeight: artwork?.fontBold ? "bold" : "normal",
                  fontFamily: artwork?.fontFamily,
                  linethrough: artwork?.fontStrikeThrough,
                  underline: artwork?.fontUnderline,
                  overline: artwork?.fontOverline,
                  fontStyle: artwork?.fontItalic ? "italic" : "normal",
                  fontSize: 30,
                });
                text.set({
                  fill: artwork?.color,
                });
                canvas.add(text);
              } else if (artwork?.objects) {
                const json = canvas?.toJSON();
                const customGraphics = artwork?.objects?.filter((j) =>
                  j?.src?.includes("/CustomGraphics/")
                );

                if (!!customGraphics?.length) {
                  const fileNames = customGraphics?.map(
                    (c) => c?.src?.split("/CustomGraphics/")[1]
                  );
                  const customGraphicsdData = await dispatch(
                    getCustomGraphics({ fileNames }, token)
                  );

                  onFabricDrop(
                    {
                      ...json,
                      objects: [...json?.objects, ...artwork?.objects],
                    },
                    customGraphicsdData?.map((q) => ({
                      productionFile: q?.Art_Url,
                      thumbnailUrl: q?.Thumbnail_Url,
                      chargeAmountInUSD: q["Charge_Amount(USD)"],
                      shirtlyChargeAmountInUsd: q["Shirtly_Amount(USD)"],
                      sku: q["Art_SKU"],
                      user: q["user"],
                      fileName: q?.Filename,
                    }))
                  );

                  canvas?.loadFromJSON({
                    ...json,
                    objects: [...json?.objects, ...artwork?.objects],
                  });
                } else {
                  onFabricDrop({
                    ...json,
                    objects: [...json?.objects, ...artwork?.objects],
                  });
                  canvas?.loadFromJSON({
                    ...json,
                    objects: [...json?.objects, ...artwork?.objects],
                  });
                }
              }

              // setOption(options.layers);
            }}
          />
        )}

        {option === options?.image && (
          <BrowseLibrary
            printType={printType}
            orderLine={orderLine}
            onDrop={async (artwork) => handleOnDrop(artwork)}
          />
        )}

        {option === options.layers && (
          <LayersDrawer
            canvas={canvas}
            state={state}
            setState={setState}
            plus={plus}
            orderLine={orderLine}
            setOrderLine={setOrderLine}
            setOption={setOption}
            options={options}
            printArea={orderLine?.printAreas.find((p) => p?.selected)}
            onApply={(json) => {
              canvas.clear();
              canvas.loadFromJSON(json);
            }}
          />
        )}

        {option === options.aiImages && (
          <AIGeneratedImages
            onDrop={async (artwork) => handleOnDrop(artwork)}
          />
        )}

        {option === options.mocks && (
          <div style={{ width: "100%" }}>
            <PHeader>
              {!mocks?.length && (
                <p
                  style={{
                    color: "#fff",
                    margin: "0 auto",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Mocks not generated!
                </p>
              )}

              {mocks?.length > 0 && <Title>Generated Mocks</Title>}

              {mocks?.length > 0 && (
                <Button
                  title="Download All"
                  containerStyle={{
                    backgroundColor: COLORS.DARK_BLUE,
                    color: COLORS.WHITE,
                    fontWeight: "normal",
                    textTransform: "none",
                    borderRadius: "6px",
                    fontSize: "13px",
                    lineHeight: "1.5",
                    boxShadow: "none",
                  }}
                  onClick={() => {
                    var zip = new JSZip();
                    var count = 0;

                    mocks?.forEach(function (url, index) {
                      var filename = `${state?.artwork?.Name}${index}.png`;

                      JSZipUtils.getBinaryContent(url, function (err, data) {
                        if (err) {
                          throw err;
                        }

                        zip.file(filename, data, { binary: true });
                        count++;

                        if (count === mocks.length) {
                          zip
                            .generateAsync({ type: "blob" })
                            .then(function callback(blob) {
                              saveAs(
                                blob,
                                `Mockups(${state?.artwork?.Name}).zip`
                              );
                            });
                        }
                      });
                    });
                  }}
                />
              )}
            </PHeader>
            <ProductColorWrapper>
              <GeneratedMockups>
                {mocks?.map((mock, index) => (
                  <a key={`Mocks${index}`} href={mock} download>
                    <ImageComponent
                      src={mock}
                      imageStyle={{ width: "100px" }}
                    />
                    <FileDownloadIcon className="download-icon" />
                  </a>
                ))}
              </GeneratedMockups>
            </ProductColorWrapper>
          </div>
        )}

        {option === options.logo && (
          <LogoGeneration onDrop={async (artwork) => handleOnDrop(artwork)} />
        )}

        {option === options.mocksLibrary && <MockLibrary />}
      </Body>

      {option === options.orders && (
        <Dialog open={option === options.orders} maxWidth="xl" fullWidth>
          <MuiDialogTitle disableTypography style={{ padding: 0 }}>
            <IconButton
              onClick={() => setOption(options.product)}
              style={{ position: "absolute", right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent>
            <Orders />
          </DialogContent>
        </Dialog>
      )}

      {option === options.threeDTool && (
        <Dialog open={option === options.threeDTool} maxWidth="xl" fullWidth>
          <MuiDialogTitle disableTypography style={{ padding: 0 }}>
            <IconButton
              onClick={() => setOption(options.product)}
              style={{ position: "absolute", right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent>
            <Model />
          </DialogContent>
        </Dialog>
      )}
    </Container>
  );
};

export default ArtworkOptions;
