import { get, put } from "@axios";
import { hideLoader, showLoader } from "actions/loader";
import { requests } from "shared/requests";

const SHOW_CART_DRAWER = "SHOW_CART_DRAWER";
const HIDE_CART_DRAWER = "HIDE_CART_DRAWER";
const SET_CART_COUNT = "SET_CART_COUNT";

const getCartItems = ({ token }) => {
  return async (dispatch) => {
    dispatch(showLoader());

    const data = await get({
      url: requests.shoppingCart,
      token,
    });

    dispatch({ type: SET_CART_COUNT, payload: { count: data?.length } });

    dispatch(hideLoader());

    return data;
  };
};

const updateCartItem = ({ data, token }) => {
  return async (dispatch) => {
    dispatch(showLoader());

    const response = await put({
      url: requests.shoppingCart,
      token,
      data,
    });

    dispatch(hideLoader());

    return response;
  };
};

const deleteCartItem = ({ data, token }) => {
  return async (dispatch) => {
    dispatch(showLoader());

    const response = await put({
      url: requests.shoppingCart,
      token,
      data,
    });

    dispatch(hideLoader());

    return response;
  };
};

export {
  SHOW_CART_DRAWER,
  HIDE_CART_DRAWER,
  SET_CART_COUNT,
  getCartItems,
  updateCartItem,
  deleteCartItem,
};
