import * as a from "actions/cart";

const initial = {
  count: 0,
  visibility: false,
};

const cart = (state = initial, action = null) => {
  switch (action.type) {
    case a.SHOW_CART_DRAWER:
      return {
        ...state,
        visibility: true,
      };

    case a.HIDE_CART_DRAWER:
      return {
        ...state,
        visibility: false,
      };

    case a.SET_CART_COUNT:
      return {
        ...state,
        count: action.payload.count,
      };

    default:
      return state;
  }
};

export default cart;
