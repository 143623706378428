import { get, post } from "@axios";
import _ from "lodash";
import { showLoader, hideLoader } from "actions/loader";
import { updateField } from "actions/common";
import { requests } from "shared/requests";
import { SET_CATEGORIES } from "actions/categories";
import { getCategoriesFromProducts } from "selectors/categories";
import { SHOW_ERROR_MODAL } from "actions/errorModal";

const SET_PRODUCTS = "SET_PRODUCTS";
const SET_PRODUCTS_ERROR = "SET_PRODUCTS_ERROR";

const getAllProducts = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const products = await get({ url: requests.getAllProducts });
      const categories = getCategoriesFromProducts(products);
      dispatch(
        updateField(SET_PRODUCTS, {
          products: _.sortBy(products, (p) => p?.Product?.DisplayName),
        })
      );
      dispatch(updateField(SET_CATEGORIES, { categories }));
    } catch (error) {
      console.log(error);
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(SET_PRODUCTS_ERROR, { error }));
    }
    dispatch(hideLoader());
  };
};

const getProduct = ({ id }) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const url = `${requests.getProduct}?id=${id}`;
      const product = await get({ url });
      dispatch(hideLoader());
      return { data: product, error: false };
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error };
    }
  };
};

const getAllDTGPlus = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const product = await get({ url: requests.getAllDTGPlus });
      dispatch(hideLoader());
      return { data: product, error: false };
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error };
    }
  };
};

const getSyncedProducts = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getSyncedProducts,
        data: { user: data?.user, shop: data.shop },
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getUserAllSyncedProducts = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.allSyncedProducts,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const removeSyncedProducts = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.removeSyncedProducts,
        data: {
          user: data?.user,
          productId: data.productId,
          Marketplace: data?.Marketplace,
        },
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editProducts = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editProduct,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editProductSize = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editSizeInformation,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }

      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editColorsInformation = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editColorsInformation,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }
      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editPrintAreas = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editPrintAreas,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }

      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editDesignerSettings = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editDesignerSettings,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }

      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editAdditionalTechniquesArtSettings = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editAdditionalTechniquesArtSettings,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }

      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editProductAdditionalImages = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editProductAdditionalImages,
        data,
      });
      dispatch(hideLoader());
      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const clearAdditionalImages = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.clearAdditionalImages,
        data,
      });
      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editArtworkSettings = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editArtworkSettings,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editPrintProvidersInformation = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editPrintProvidersInformation,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const deleteProduct = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.deleteProduct,
        data,
      });
      dispatch(hideLoader());
      dispatch(getAllProducts());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editDTGPlusInformation = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editDTGPlusInformation,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updateDTGPlusInfo = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.updateDTGPlusInfo,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const deleteDTGPlusInfo = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.deleteDTGPlusInfo,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const ssaActiveWearUSProducts = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await get({
        url: requests.ssactivewearUSproducts,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const ssActiveWearCAProducts = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await get({
        url: requests.ssactivewearCAproducts,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const alphaInventoryCA = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.alphaInventoryCA,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const alphaInventoryUS = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.alphaInventoryUS,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getShopifyProducts = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getShopifyProducts,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getWooProducts = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getWooProducts,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const syncProduct = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.syncProduct,
        data,
      });

      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const syncedProducts = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.syncedProducts,
        data,
      });

      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getShopifyMoreProducts = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getShopifyMoreProducts,
        data,
      });

      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getShopifyProductsCount = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getShopifyCountProducts,
        data,
      });

      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const fetchSyncedProductsCount = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.syncedProductsCount,
        data,
      });

      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getWooMoreProducts = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const products = await post({
        url: requests.getWooMoreProducts,
        data,
      });

      dispatch(hideLoader());

      return products;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const syncedProduct = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const product = await post({
        url: requests.syncedProduct,
        data,
      });

      dispatch(hideLoader());

      return product;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getThisProduct = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const product = await post({
        url: requests.getThisProduct,
        data,
      });

      dispatch(hideLoader());

      return product;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updateSyncedProduct = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.updateSyncedProduct,
        data,
      });

      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const editProductSubstituteInformation = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editProductSubstituteInformation,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editSKUInformation = (data, fetchAllProducts = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editSKUInformation,
        data,
      });

      if (fetchAllProducts) {
        await dispatch(getAllProducts());
      }
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updateScreenPrintingInfo = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.updateScreenPrintingInfo,
        data,
      });

      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getAllScreenPrinting = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await get({
        url: requests.getAllScreenPrinting,
      });

      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const deleteScreenPrintingInfo = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.deleteScreenPrintingInfo,
        data,
      });

      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getShipstationCarriers = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getShipstationCarriers,
      });

      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const getShipstationRates = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getShipstationRates,
        data,
      });

      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

export {
  alphaInventoryUS,
  alphaInventoryCA,
  ssaActiveWearUSProducts,
  ssActiveWearCAProducts,
  getAllProducts,
  getProduct,
  SET_PRODUCTS,
  SET_PRODUCTS_ERROR,
  getSyncedProducts,
  removeSyncedProducts,
  editProducts,
  editProductSize,
  editColorsInformation,
  editDesignerSettings,
  editPrintProvidersInformation,
  deleteProduct,
  getAllDTGPlus,
  editDTGPlusInformation,
  updateDTGPlusInfo,
  deleteDTGPlusInfo,
  editArtworkSettings,
  editProductAdditionalImages,
  clearAdditionalImages,
  editAdditionalTechniquesArtSettings,
  editPrintAreas,
  getShopifyProducts,
  getWooProducts,
  syncProduct,
  syncedProducts,
  getShopifyMoreProducts,
  getShopifyProductsCount,
  fetchSyncedProductsCount,
  getWooMoreProducts,
  syncedProduct,
  updateSyncedProduct,
  getThisProduct,
  getUserAllSyncedProducts,
  editProductSubstituteInformation,
  updateScreenPrintingInfo,
  getAllScreenPrinting,
  deleteScreenPrintingInfo,
  getShipstationCarriers,
  getShipstationRates,
  editSKUInformation,
};
