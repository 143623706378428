import React, { useState } from "react";
import Button from "components/Button";
import TextField from "components/TextField";
import Dialog from "@material-ui/core/Dialog";
import { ModalContainer, Footer } from "styles/pages/my-stores/registration";

const DuplicateOrderModal = ({ open, setModal }) => {
  const [value, setValue] = useState(1);
  return (
    <Dialog maxWidth="md" open={open} onClose={() => setModal(false)}>
      <ModalContainer>
        <h1>Duplicate Orders</h1>
        <p>Please enter the times you want to duplicate this order</p>

        <TextField
          label="Value"
          type="number"
          value={value || 1}
          onChange={(v) => setValue(v > 0 ? v : 1)}
        />
        <Footer>
          <Button
            variant="text"
            title="Cancel"
            onClick={() => setModal({ show: false })}
            containerStyle={{ backgroundColor: "red", color: "#fff" }}
          />
          <Button
            variant="text"
            title="Ok"
            onClick={() => setModal({ show: false, value })}
            containerStyle={{ backgroundColor: "#000", color: "#fff" }}
          />
        </Footer>
      </ModalContainer>
    </Dialog>
  );
};

export default DuplicateOrderModal;
