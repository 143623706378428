import React, { useState, useEffect } from "react";
import EditTextDrawer from "components/ArtWork/EditTextDrawer";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";
import {
  LayersContainer,
  LayersWrapper,
  Layer,
  LayerActions,
  TextLayer,
  UpperLayer,
  LayerText,
} from "styles/components/ArtWork/browseLibrary";
import LayersPopoverOptions from "components/ArtWork/LayersPopoverOptions";

const SortingButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 4px !important;

  &.MuiIconButton-root {
    margin: -4px 0;
  }

  .MuiSvgIcon-root {
    font-size: 18px;
    color: #fff;
    cursor: pointer;
  }
`;

const LayersDrawer = ({
  canvas,
  onApply,
  printArea,
  plus,
  orderLine,
  setOrderLine,
  setOption,
  options,
  state,
  setState,
}) => {
  // const [canvasJSON, setCanvasJSON] = useState(canvas?.toJSON());
  const canvasJSON = canvas?.toJSON();

  const [editTextDrawer, setEditTextDrawer] = useState(false);
  const [activeObjectIndex, setActiveObjectIndex] = useState(0);
  const printType = orderLine?.PrintType;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!!canvas?.getObjects()?.length)
      canvas.setActiveObject(canvas?.getObjects()[activeObjectIndex]);
    canvas.requestRenderAll();
  }, [activeObjectIndex]);

  if (!canvasJSON?.objects?.length)
    return (
      <p
        style={{
          padding: "20px",
          fontSize: 20,
          color: "#fff",
          width: "100%",
          textAlign: "center",
        }}
      >
        Please add text or image on the board.
      </p>
    );

  const handleMoveLayer = (index, direction) => {
    if (!canvas) return;

    const objects = canvas.getObjects();

    if (direction === "up" && index > 0) {
      // Move object up (swap with previous object)
      const currentObject = objects[index];
      const prevObject = objects[index - 1];

      canvas.moveTo(currentObject, index - 1);
      canvas.moveTo(prevObject, index);

      // Update active object index
      setActiveObjectIndex(index - 1);
    } else if (direction === "down" && index < objects.length - 1) {
      // Move object down (swap with next object)
      const currentObject = objects[index];
      const nextObject = objects[index + 1];

      canvas.moveTo(currentObject, index + 1);
      canvas.moveTo(nextObject, index);

      // Update active object index
      setActiveObjectIndex(index + 1);
    }

    canvas.renderAll();
    onApply(canvas.toJSON());
  };

  return (
    <>
      <LayersContainer>
        {/* <Header>
          <h3>Layers ({printArea?.printAreaName})</h3>
        </Header> */}
        <LayersWrapper>
          {canvasJSON?.objects?.map((o, i) => {
            const widthInPixel =
              (Number(o?.width) * o?.scaleX) /
              printArea?.designerSettings?.multiplier;
            const v = printArea?.designerSettings?.width;
            const widthInInches =
              o?.width < 1
                ? 255 / printArea?.designerSettings?.multiplier
                : widthInPixel > v
                ? v
                : widthInPixel;

            const heightInPixel =
              (Number(o?.height) * o?.scaleY) /
              printArea?.designerSettings?.multiplier;
            const vh = printArea?.designerSettings?.height;
            const heightInInches =
              o?.height < 1
                ? 255 / printArea?.designerSettings?.multiplier
                : heightInPixel > vh
                ? vh
                : heightInPixel;

            const widthPercentage = parseInt(
              ((+o?.width / 255) * 100) / +widthInInches
            );

            const isCustomGraphics = o?.src?.includes("/CustomGraphics/");

            return (
              <UpperLayer
                selected={activeObjectIndex === i}
                key={`objectsLayer${i}`}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SortingButtons>
                    <StyledIconButton
                      size="small"
                      onClick={() => handleMoveLayer(i, "up")}
                      disabled={i === 0}
                    >
                      <ArrowUpwardIcon />
                    </StyledIconButton>
                    <StyledIconButton
                      size="small"
                      onClick={() => handleMoveLayer(i, "down")}
                      disabled={i === canvasJSON.objects.length - 1}
                    >
                      <ArrowDownwardIcon />
                    </StyledIconButton>
                  </SortingButtons>
                  <Layer
                    onClick={() => {
                      setActiveObjectIndex(i);
                    }}
                  >
                    {o?.type === "image" && (
                      <img
                        key={i}
                        src={o?.src}
                        width="24"
                        alt=""
                        style={{ borderRadius: 3 }}
                      />
                    )}

                    {(o?.type === "i-text" || o?.type === "group") && (
                      <TextLayer>
                        <TextFieldsIcon style={{ fontSize: 18 }} />
                      </TextLayer>
                    )}

                    <LayerText
                      style={
                        {
                          // fontWeight: o?.fontWeight,
                          // fontFamily: o?.fontFamily,
                          // fontStyle: o?.fontStyle,
                          // textDecoration: `${o?.underline ? "underline" : ""} ${
                          //   o?.overline ? "overline" : ""
                          // } ${o?.linethrough ? "line-through" : ""}`,
                        }
                      }
                    >
                      {o?.type === "i-text" || o?.type === "group"
                        ? o?.text || o?.objects?.map((c) => c?.text)?.join("")
                        : `Image`}
                    </LayerText>

                    {activeObjectIndex === i && (
                      <LayerActions>
                        {/* <Tooltip title="Visibility">
                        <VisibilityIcon
                          style={{ opacity: o?.visible ? 1 : 0.6 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            const obj = canvas.getActiveObject();
                            obj.set({ visible: !obj.visible });
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", { target: obj });
                          }}
                        />
                      </Tooltip> */}

                        {printType !== "EMB" && o?.type !== "image" && (
                          <Tooltip title="Clone">
                            <FileCopyIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                const data = {
                                  ...canvasJSON,
                                  objects: [
                                    ...canvasJSON.objects,
                                    {
                                      ...o,
                                      top: o?.top + 10,
                                      left: o?.left + 10,
                                    },
                                  ],
                                };

                                // setCanvasJSON(data);
                                onApply(data);
                                setTimeout(() => {
                                  setActiveObjectIndex(
                                    canvas?.toJSON()?.objects?.length - 1
                                  );
                                }, 100);
                              }}
                            />
                          </Tooltip>
                        )}

                        <Tooltip title="Delete">
                          <HighlightOffIcon
                            style={{ color: "red" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              // const data = {
                              //   ...canvasJSON,
                              //   objects: canvasJSON.objects.filter(
                              //     (item, j) => j !== i
                              //   ),
                              // };

                              // setCanvasJSON(data);
                              // onApply(data);

                              canvas.remove(canvas.getObjects()[i]);
                              canvas.renderAll();

                              if (!canvasJSON.objects?.length) {
                                setOption(options.text);
                                return;
                              }

                              setActiveObjectIndex(0);

                              // if (o?.type === "image") {
                              //   const printAreas = orderLine?.printAreas?.map(
                              //     (p) => {
                              //       if (!p?.selected) return p;

                              //       const customGraphicsdData =
                              //         p?.customGraphicsdData;

                              //       return {
                              //         ...p,
                              //         customGraphicsdData:
                              //           customGraphicsdData?.filter(
                              //             (c) =>
                              //               !o?.src?.includes(
                              //                 c?.fileName?.split(".")[0]
                              //               )
                              //           ),
                              //       };
                              //     }
                              //   );
                              //   setOrderLine({
                              //     ...orderLine,
                              //     printAreas,
                              //   });
                              // }
                            }}
                          />
                        </Tooltip>
                      </LayerActions>
                    )}

                    {/* <div style={{ marginTop: 5, display: "none" }}>
                      <span style={{ marginRight: 10 }}>
                        <b>
                          Print Quality:&nbsp;
                          {o?.type === "i-text" || isCustomGraphics ? (
                            <span style={{ color: "green" }}>Good</span>
                          ) : (
                            <span
                              style={{
                                color:
                                  widthPercentage < 20
                                    ? "red"
                                    : widthPercentage > 20 &&
                                      widthPercentage < 50
                                    ? "#f36c62"
                                    : widthPercentage > 50 &&
                                      widthPercentage < 80
                                    ? "orange"
                                    : "green",
                              }}
                            >
                              {widthPercentage < 20
                                ? "Very Poor"
                                : widthPercentage > 20 && widthPercentage < 50
                                ? "Poor"
                                : widthPercentage > 50 && widthPercentage < 80
                                ? "Average"
                                : "Good"}
                            </span>
                          )}
                        </b>
                      </span>
                    </div> */}

                    {/* <div style={{ marginTop: 5 }}>
                      <span style={{ marginRight: 10 }}>
                        <b>Width:</b>&nbsp;
                        {widthInInches.toFixed(2)}"
                      </span>
                      <span>
                        <b>Height:</b>&nbsp;
                        {heightInInches.toFixed(2)}"
                      </span>
                    </div> */}
                  </Layer>
                </div>
              </UpperLayer>
            );
          })}
        </LayersWrapper>
      </LayersContainer>

      {!!editTextDrawer && (
        <EditTextDrawer
          plus={plus}
          open={!!editTextDrawer}
          toggle={() => setEditTextDrawer(false)}
          onDrop={(json) => {
            const data = {
              ...canvasJSON,
              objects: canvasJSON.objects?.map((o, i) => {
                if (i === json?.index) return json;
                return o;
              }),
            };
            // setCanvasJSON(data);
            setEditTextDrawer(false);
            onApply(data);
          }}
          data={editTextDrawer}
        />
      )}

      <LayersPopoverOptions
        canvas={canvas}
        onApply={onApply}
        orderLine={orderLine}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        activeObjectIndex={activeObjectIndex}
        // setCanvasJSON={setCanvasJSON}
        canvasJSON={canvasJSON}
        state={state}
        setState={setState}
        options={options}
        setOption={setOption}
      />
    </>
  );
};

export default LayersDrawer;
