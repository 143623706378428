import axios from "axios";
import { baseURL } from "shared/requests";

const instance = axios.create({
  baseURL,
});

const get = async ({ url, token }) => {
  try {
    if (token) {
      instance.defaults.headers.common["authorization"] = token;
    }

    const request = await instance.get(url);
    return request?.data;
  } catch (error) {
    throw error;
  }
};

const post = async ({ url, data, token }) => {
  if (token) {
    instance.defaults.headers.common["authorization"] = token;
  }
  try {
    const request = await instance.post(url, data);
    return request?.data;
  } catch (error) {
    throw error;
  }
};

const put = async ({ url, data, token }) => {
  if (token) {
    instance.defaults.headers.common["authorization"] = token;
  }
  try {
    const request = await instance.put(url, data);
    return request?.data;
  } catch (error) {
    throw error;
  }
};

export { get, post, put };
