import React, { useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import { getTags } from "actions/tags";
import { checkAppVersion, checkUserStatus } from "actions/user";
import Layout from "layout";
import { getAllProducts } from "actions/products";
import Loader from "components/Loader";
// import Dashboard from "pages/dashboard";
// import Catalog from "pages/catalog";
// import BrowseCatalog from "pages/browse-catalog";
import EditProducts from "pages/edit-products";
// import ProductDetail from "pages/product-detail";
import AIMockUpGenerator from "pages/ai-mock-up-generator";
// import Terms from "pages/terms";
// import Support from "pages/support";
import CustomizerTool from "pages/customizer-tool";
// import Announcements from "pages/announcements";
// import AdminScreenPrinting from "pages/admin-screen-printing";
import AccountSettings from "pages/account-settings";
// import MyStores from "pages/my-stores/stores";
// import MyStoresRegistration from "pages/my-stores/registration";
// import ConnectToShopify from "pages/connect-to-shopify";
// import ConnectToWooCommerce from "pages/connect-to-woo-commerce";
import { getGoogleFonts } from "actions/googleFonts";
// import Orders from "pages/orders";
// import OrderDetails from "pages/order-details";
import Home from "pages/home";
// import AdminSettings from "pages/admin-settings";
import AdminUsers from "pages/admin-users";
import CreateProduct from "pages/create-product";
// import Inventory from "pages/inventory";
// import Notifications from "pages/notifications";
// import BackgroundBurner from "pages/background-burner";
// import DtgPlus from "pages/dtg-plus";
// import HowToConnectBigCommerce from "pages/how-to-connect-big-commerce";
// import SalesReport from "pages/sales-report";
// import NewsRoom from "pages/news-room";
// import OrderStock from "pages/order-stock";
// import FulfillmentDays from "pages/fulfillment-days";
// import SubmittedStockOrders from "pages/submitted-stock-orders";
// import WebPages from "pages/web-pages";
// import IPProgram from "pages/ip-program";
// import SyncProducts from "pages/my-stores/sync-products";
// import SyncProduct from "pages/sync-product";
import { ROUTES } from "shared/constants/routes";
import { store, persistor } from "store";
// import Products from "pages/my-stores/products";
import Warehouse from "pages/warehouse";
// import VerifyArtFiles from "pages/verify-art-files";
import ErrorModal from "components/ErrorModal";
import { getWebPage } from "actions/webPages";
import { logout } from "actions/auth";
import YotiVerificationModal from "components/YotiVerificationModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "driver.js/dist/driver.css";
import { getCartItems } from "actions/cart";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_test_1jly266CPr5zNJPdPLaAAKdE00UtQzQcTE");

const App = () => {
  const dispatch = useDispatch();
  const loaderVisibility = useSelector((state) => state?.loader?.visibility);
  const auth = useSelector((state) => state?.auth);
  const products = useSelector((state) => state?.products?.data?.length);
  const tags = useSelector((state) => !!state?.tags?.data?.length);
  const googleFonts = useSelector((state) => state?.googleFonts?.data);
  const yotiModallVisibility = useSelector((state) => state?.yoti?.visibility);
  const token = useSelector((state) => state?.auth?.profile?.token);

  useEffect(() => {
    const fetchGoogleFonts = async () => {
      await dispatch(getGoogleFonts());
    };
    if (!googleFonts?.items?.length) fetchGoogleFonts();
    if (googleFonts) {
      const fonts = googleFonts?.items?.filter(
        (d) => d?.version === "v15" || d?.version === "v14"
      );
      let family = "";
      for (let i = 0; i < fonts?.length; i++) {
        family += `family=${fonts[i].family?.replace(/\s/g, "+")}&`;
      }
      family += "display=swap";
      var style = document.createElement("link");
      style.rel = "stylesheet";
      style.href = `https://fonts.googleapis.com/css2?${family}`;
      document.getElementsByTagName("head")[0].append(style);
    }
  }, [googleFonts]);

  useEffect(() => {
    const getUserStatusData = async () => {
      const data = await dispatch(
        checkUserStatus(auth?.profile?.emailid, token)
      );
      if (data?.Status === "In-Active") {
        dispatch(logout());
      }
    };

    if (auth?.authenticated) {
      getUserStatusData();
    }
  }, [auth, dispatch]);

  useEffect(() => {
    const loadData = () => {
      if (!products) {
        return dispatch(getAllProducts());
      }

      if (!tags) {
        return dispatch(getTags());
      }
    };
    loadData();
  }, [dispatch, products, tags]);

  useEffect(() => {
    const saveAppVersion = async () => {
      const version = await dispatch(checkAppVersion());
      window.localStorage.setItem("appVersion", version?.CurrentAppVersion);
    };
    saveAppVersion();
  }, [dispatch]);

  useEffect(() => {
    const getWebPageData = async () => {
      await dispatch(getWebPage({ page: "LandingPage" }));
    };

    if (auth?.authenticated) {
      getWebPageData();
    }
  }, [auth, dispatch]);

  useEffect(() => {
    const getCart = async () => {
      await dispatch(getCartItems({ token }));
    };

    if (auth?.authenticated) {
      getCart();
    }
  }, [auth, dispatch]);

  return (
    <React.Fragment>
      <Router>
        <Route exact path={ROUTES.HOME.LINK} component={Home} />
        {/* <Route
          exact
          path={ROUTES.ANNOUNCEMENTS.LINK}
          component={Announcements}
        /> */}
        {/* <Route exact path={ROUTES.TERMS_AND_SERVICES.LINK} component={Terms} />
        <Route exact path={ROUTES.NEWS_ROOM.LINK} component={NewsRoom} />
        <Route exact path={ROUTES.SUPPORT.LINK} component={Support} />
        <Layout path={ROUTES.DASHBOARD.LINK} component={Dashboard} />
        <Layout path={ROUTES.CATALOG.LINK} component={Catalog} /> */}
        <Layout
          path={ROUTES.AI_MOCK_UP_GENERATOR.LINK}
          component={AIMockUpGenerator}
        />
        <Layout
          path={`${ROUTES.AI_MOCK_UP_GENERATOR.LINK}/:cartItemId`}
          component={AIMockUpGenerator}
        />
        {/* <Layout path={ROUTES.BROWSE_CATALOG.LINK} component={BrowseCatalog} />
        <Layout
          path={`${ROUTES.PRODUCT_DETAIL.LINK}/:id`}
          component={ProductDetail}
        /> */}
        <Layout
          path={ROUTES.ACCOUNT_SETTINGS.LINK}
          component={AccountSettings}
        />
        {/* <Layout
          path={ROUTES.HOW_TO_CONNECT_BIG_COMMERCE.LINK}
          component={HowToConnectBigCommerce}
        />
        <Layout path={ROUTES.MY_STORES.LINK} component={MyStores} />
        <Layout path={ROUTES.MY_STORES_PRODUCTS.LINK} component={Products} />

        <Layout
          path={ROUTES.MY_STORES_REGISTRATION.LINK}
          component={MyStoresRegistration}
        /> */}
        {/* <Layout
          path={ROUTES.ADMIN_SCREEN_PRINTING.LINK}
          component={AdminScreenPrinting}
        /> */}
        {/* <Layout
          path={ROUTES.CONNECT_TO_SHOPIFY.LINK}
          component={ConnectToShopify}
        />
        <Layout path={ROUTES.ORDERS.LINK} component={Orders} />
        <Layout
          path={`${ROUTES.ORDER_DETAILS.LINK}/:orderId`}
          component={OrderDetails}
        />
        <Layout
          path={`${ROUTES.ORDER_DETAILS.LINK}/:orderId/:shopName`}
          component={OrderDetails}
        /> */}
        {/* <Layout path={ROUTES.ADMIN_SETTINGS.LINK} component={AdminSettings} /> */}
        <Layout path={ROUTES.ADMIN_USERS.LINK} component={AdminUsers} />
        <Layout
          path={ROUTES.ADMIN_EDIT_PRODUCT.LINK}
          component={EditProducts}
        />

        <Layout
          path={ROUTES.ADMIN_CREATE_PRODUCT.LINK}
          component={CreateProduct}
        />

        <Layout path={ROUTES.CUSTOMIZER_TOOL.LINK} component={CustomizerTool} />

        <Layout path={ROUTES.WAREHOUSE.LINK} component={Warehouse} />
        {/* <Layout path={ROUTES.INVENTORY.LINK} component={Inventory} /> */}
        {/* <Layout path={ROUTES.ADMIN_SALES_REPORT.LINK} component={SalesReport} /> */}
        {/* <Layout path={ROUTES.USER_SALES_REPORT.LINK} component={SalesReport} />
         */}
        {/* <Layout path={ROUTES.ADMIN_DTG_PLUS.LINK} component={DtgPlus} /> */}

        {/* <Layout path={ROUTES.ORDER_STOCK.LINK} component={OrderStock} /> */}
        {/* <Layout
          path={ROUTES.BACKGROUND_BURNER.LINK}
          component={BackgroundBurner}
        />
        <Layout
          path={ROUTES.FULFILLMENT_DAYS.LINK}
          component={FulfillmentDays}
        />
        <Layout path={ROUTES.WEB_PAGES.LINK} component={WebPages} /> */}
        {/* <Layout
          path={ROUTES.CONNECT_TO_WOO_COMMERCE.LINK}
          component={ConnectToWooCommerce}
        />
        <Layout path={ROUTES.NOTIFICATIONS.LINK} component={Notifications} />
        <Layout
          path={ROUTES.SUBMITTED_ORDER_STOCK.LINK}
          component={SubmittedStockOrders}
        />
        <Layout
          exact
          path={`${ROUTES.MY_STORES_SYNC_PRODUCT.LINK}/:productId/:marketPlaceType/:shopName`}
          component={SyncProduct}
        />
        <Layout
          path={ROUTES.MY_STORES_SYNC_PRODUCTS.LINK}
          component={SyncProducts}
        />

        <Layout path={ROUTES.IP_PROGRAM.LINK} component={IPProgram} /> */}
        {/* <Layout path={ROUTES.ADMIN_IP_PROGRAM.LINK} component={IPProgram} /> */}
        {/* <Layout
          path={ROUTES.VERIFY_ART_FILES.LINK}
          component={VerifyArtFiles}
        /> */}
        <Route exact path={ROUTES.HOME.LINK}>
          {auth?.authenticated ? (
            // auth?.profile?.IPUser ? (
            //   <Redirect to={ROUTES.IP_PROGRAM.LINK} />
            // ) : (
            <Redirect to={ROUTES.AI_MOCK_UP_GENERATOR.LINK} />
          ) : (
            // )
            <Redirect to={ROUTES.HOME.LINK} />
          )}
        </Route>
      </Router>

      {loaderVisibility && <Loader open={loaderVisibility} />}
      {yotiModallVisibility && <YotiVerificationModal />}
    </React.Fragment>
  );
};

const Wrapper = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Elements stripe={stripePromise}>
          <App />
          <ErrorModal />
        </Elements>
      </PersistGate>
    </Provider>
  );
};

export default Wrapper;
