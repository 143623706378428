import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LockIcon from "@material-ui/icons/Lock";
import styled from "styled-components";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "actions/loader";
import { generateShortUuid } from "utils/generateShortUuid";
import { createPayment, createTokenPayment } from "actions/orders";
import { checkUserStatus } from "actions/user";

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  border-bottom: 1px solid #eee;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    /* height: 800px; */
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 24px;
  /* height: calc(100% - 140px); */
  overflow-y: auto;
`;

const TokenSection = styled.div`
  margin-bottom: 24px;
  padding: 16px;
  background: #f5f5f5;
  border-radius: 8px;
`;

const PaymentSection = styled.div`
  margin-top: 24px;
  padding: 16px;
  border: 1px dashed #ccc;
  border-radius: 10px;
`;

const PaymentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
  color: gray;
  font-weight: bold;
`;

const TotalSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
`;

const stripeOptions = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  hidePostalCode: true,
};

const PRICE_PER_TOKEN = 0.2; // Price in dollars per token

const PaymentDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [tokenQuantity, setTokenQuantity] = useState(5);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const profile = useSelector((state) => state?.auth?.profile);
  const totalAmount = tokenQuantity ? tokenQuantity * PRICE_PER_TOKEN : 0;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    if (!stripe || !elements || !tokenQuantity || tokenQuantity < 5) {
      setLoading(false);
      return;
    }

    try {
      dispatch(showLoader());
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) {
        setError(error.message);
        setLoading(false);
        return;
      }

      const orderNumber = generateShortUuid(6);

      await dispatch(
        createTokenPayment(
          {
            paymentMethodId: paymentMethod.id,
            amount: totalAmount * 100,
            email: profile?.emailid,
            name: profile?.FullName,
            orderNumber,
            tokenQuantity,
          },
          profile?.token
        )
      );

      await dispatch(checkUserStatus(profile?.emailid, profile?.token));

      dispatch(hideLoader());

      setLoading(false);
      onClose();
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleQuantityChange = (e) => {
    const value = Number(e.target.value);
    if (value >= 5) {
      setTokenQuantity(value);
    } else {
      setTokenQuantity(5);
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogHeader>
        <DialogTitle style={{ padding: 0 }}>Purchase AI Tokens</DialogTitle>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <StyledDialogContent>
        <form onSubmit={handleSubmit}>
          <TokenSection>
            <TextField
              fullWidth
              label="Number of Tokens"
              type="number"
              value={tokenQuantity}
              onChange={handleQuantityChange}
              inputProps={{ min: 5 }}
              error={tokenQuantity !== "" && tokenQuantity < 5}
              helperText={
                tokenQuantity !== "" && tokenQuantity < 5
                  ? "Minimum purchase is 5 tokens"
                  : ""
              }
              required
            />
            <TotalSection>
              <Typography>Price per token:</Typography>
              <Typography>${PRICE_PER_TOKEN.toFixed(2)}</Typography>
            </TotalSection>
            <TotalSection>
              <Typography variant="h6">Total Amount:</Typography>
              <Typography variant="h6">${totalAmount.toFixed(2)}</Typography>
            </TotalSection>
          </TokenSection>

          <PaymentSection>
            <PaymentHeader>
              <LockIcon />
              <Typography>Secure Payment with Stripe</Typography>
            </PaymentHeader>
            <CardElement options={stripeOptions} />
            {error && (
              <Typography color="error" style={{ marginTop: 16 }}>
                {error}
              </Typography>
            )}
          </PaymentSection>
        </form>
      </StyledDialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!stripe || loading || !tokenQuantity || tokenQuantity < 5}
          style={{
            backgroundColor: "#000",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          {loading ? "Processing..." : `Purchase ${tokenQuantity || 0} Tokens`}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default PaymentDialog;
