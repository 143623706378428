import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import LockIcon from "@material-ui/icons/Lock";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import { getCountries } from "actions/countries";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import Select from "components/Select";
import {
  createPayment,
  shippingRates,
  submitOrderToCanada,
} from "actions/orders";
import _ from "lodash";
import { generateShortUuid } from "utils/generateShortUuid";
import moment from "moment";
import { HIDE_CART_DRAWER } from "actions/cart";
import { getUserInformation } from "actions/user";
import ErrorMessage from "components/ErrorMessage";
import { updateField } from "actions/common";
import { SHOW_YOTI_MODAL } from "actions/yoti";

const regExpr = /[^a-zA-Z0-9- ]/g;

const CartFooter = styled.div`
  padding: 16px 16px 0;
  border-top: 1px solid #eee;
  background: white;
  width: 100%;
`;

const TotalSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const stripeOptions = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  hidePostalCode: true,
};

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  border-bottom: 1px solid #eee;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    height: 680px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 24px;
  height: calc(100% - 140px); /* Adjust for header and footer height */
  overflow-y: auto;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 16px 24px;
  border-top: 1px solid #eee;
`;

const ShippingAddressModal = ({ open, onClose, getItems, items, subTotal }) => {
  const [shippingCost, setShippingCost] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "CA",
  });

  const dispatch = useDispatch();
  const countries = useSelector((state) => state?.countries?.data);
  const stripe = useStripe();
  const elements = useElements();
  const [user, setUser] = useState(undefined);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const request = await dispatch(getUserInformation(emailid));
      if (request?.error) {
        return;
      }
      setFormData((prev) => ({
        ...prev,
        name: request?.User?.FullName,
        address1: request?.User?.AddressLineOne,
        address2: request?.User?.AddressLineTwo,
        city: request?.User?.City,
        state: request?.User?.StateProvince,
        // zipCode: request?.User?.ZipPostal,
      }));
      setUser(request);
    };
    fetchUserDetails();
  }, []);

  useEffect(() => {
    const loadCountries = async () => {
      if (!countries?.length) {
        dispatch(getCountries());
      }
    };
    loadCountries();
  }, [dispatch, countries]);

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: formData.name,
        email: emailid,
        // phone: values.phone,
        address: {
          line1: formData.address1,
          line2: formData.address2,
          city: formData.city,
          state: formData.state,
          postal_code: formData.zipCode,
          country: "CA",
        },
      },
    });

    if (error) {
      dispatch(setError(error.message));
      setLoading(false);
      return;
    }

    const orderNumber = generateShortUuid(6);
    const total = subTotal + shippingCost + tax;

    const response = await dispatch(
      createPayment(
        {
          paymentMethodId: paymentMethod.id,
          amount: total * 100,
          email: emailid,
          name: formData.name,
          orderNumber,
          shippingAddress: {
            name: formData.name,
            address: formData.addressLine1,
            address2: formData.addressLine2,
            city: formData.city,
            state: formData.state,
            postal_code: formData.zipCode,
            country: "CA",
          },

          metadata: {
            orderNumber,

            orderedItems: items,
            shippingAddress: {
              name: formData.name,
              address: formData.address1,
              address2: formData.address2,
              city: formData.city,
              state: formData.state,
              postal_code: formData.zipCode,
              country: "CA",
            },
            billingAddress: {
              name: formData.name,
              address: formData.address1,
              address2: formData.address2,
              city: formData.city,
              state: formData.state,
              postal_code: formData.zipCode,
              country: "CA",
            },
            sameBillingShipping: true,
            shippingService: "Standard",
            selectedDeliveryMethod: "Standard",
            paymentDetails: {
              subTotal: subTotal,
              taxEstimate: Number(tax.toFixed(2)),
              orderTotal: total,
              shippingCost,
            },
          },
        },
        token
      )
    );

    if (response?.status === "succeeded") {
      let itemsXml = "";

      for (let i = 0; i < items?.length; i++) {
        const item = items[i];
        const sizes = item?.product?.Sizes?.filter((s) => s?.qty > 0);

        for (let j = 0; j < sizes?.length; j++) {
          itemsXml += `<Item>
        <SKU>${item?.product?.Product.SKU?.replace(regExpr, "")}</SKU>
        <Descrip>[${item?.product?.Product.PrintType?.replace(
          regExpr,
          ""
        )}] ${item?.product?.Product.SKU?.replace(regExpr, "")}</Descrip>
        <Color>${item?.colors[0]?.Name.replace(regExpr, "")}</Color>
        <Qty>${sizes[j].qty}</Qty>
        <Size>${sizes[j].Name.replace(regExpr, "")}</Size>
        <Type>${item?.product?.Product.PrintType?.replace(regExpr, "")}</Type>
        <Style>${item?.product?.Product.SKU?.replace(regExpr, "")}</Style>
        <ProcessMode>Piece</ProcessMode>
        
        
        <Design>
              <Location>FRONT</Location>
              <Thumb>${item?.colors[0]?.mockImagesResponse?.thumbImage.replace(
                ".jpg",
                ".png"
              )}</Thumb>
              <SourceFile>${item?.colors[0]?.mockImagesResponse?.artworkUrl.replace(
                ".jpg",
                ".png"
              )}</SourceFile>
              <Width></Width>
              <Height></Height>
              <LOffset></LOffset>
              <TOffset></TOffset>
              <WhiteUnderbaseRequired>true</WhiteUnderbaseRequired>
            </Design>
        </Item>`;
        }
      }

      const xml = `<AddXml xmlns="http://tempuri.org/">
                    <OrderString>
                      <Request>
                        <CustID>1</CustID>
                        <PO>VIZ-${orderNumber}</PO>
                        <OrderDate>${moment().format("YYYY-MM-DD")}</OrderDate>
                        <Source>Vizual</Source>
                        <Store>Vizual</Store>
                        <StoreID>1</StoreID>
                        <ShipTo>
                            <FirstName>${formData?.name?.replace(
                              regExpr,
                              ""
                            )}</FirstName>
                            <LastName></LastName>
                            <Company></Company>
                            <Adrx1>${formData?.address1?.replace(
                              regExpr,
                              ""
                            )}</Adrx1>
                            <Adrx2>${formData?.address2?.replace(
                              regExpr,
                              ""
                            )}</Adrx2>
                            <City>${formData?.city?.replace(regExpr, "")}</City>
                            <State>${formData?.state?.replace(
                              regExpr,
                              ""
                            )}</State>
                            <Zip>${formData?.zipCode
                              ?.toString()
                              ?.replace(regExpr, "")}</Zip>
                            <Country>CA</Country>
                            <Email>${emailid}</Email>
                        </ShipTo>
                        <ShipFrom>
                          <FirstName>Vizual</FirstName>
                          <LastName></LastName>
                          <Company>Vizual</Company>
                          <Adrx1>122 Middleton St.</Adrx1>
                          <Adrx2></Adrx2>
                          <City>Brantford</City>
                          <State>ON</State>
                          <Zip>N3S7V7</Zip>
                          <Country>CA</Country>
                          <Email>info@bigoventees.com</Email>
                        </ShipFrom>
                        <ShipMethod>Canada Post</ShipMethod>
                        <ProductionPriority>Normal</ProductionPriority>
                        <Notes></Notes>
                        ${itemsXml}
                    </Request>
                  </OrderString>
                </AddXml>`;

      // await dispatch(submitOrderToCanada(xml, token));
      await getItems();
      dispatch({ type: HIDE_CART_DRAWER });
      alert("Order Submitted Successfully!");
      onClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const tax = Number(((subTotal + shippingCost) * 0.13).toFixed(2));

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogHeader>
        <DialogTitle style={{ padding: 0 }}>Payment Method</DialogTitle>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <StyledDialogContent>
        <Grid container spacing={3}>
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Email"
                value={emailid}
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Address Line 1"
                name="address1"
                value={formData.address1}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Address Line 2"
                name="address2"
                value={formData.address2}
                onChange={handleChange}
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Select
                label="State/Province *"
                labelKey="name"
                valueKey="code"
                options={_.find(countries, (o) => o.code2 === "CA")?.states}
                value={{ code: formData.state }}
                onChange={(v) => {
                  setFormData((prev) => ({
                    ...prev,
                    state: v,
                  }));
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="ZIP/Postal Code"
                name="zipCode"
                value={formData.zipCode}
                onBlur={async (e) => {
                  const toPostalCode = e.target.value;

                  if (toPostalCode?.length < 6) return;

                  let totalWeight = 0;

                  for (let i = 0; i < items?.length; i++) {
                    const item = items[i];
                    const sizes = item?.product?.Sizes;
                    const weight = item?.product?.Product?.weight;
                    let qty = 0;

                    for (let j = 0; j < sizes.length; j++) {
                      if (sizes[j]?.qty > 0) qty += sizes[j]?.qty;
                    }

                    totalWeight += weight * qty;
                  }

                  if (toPostalCode) {
                    const payload = {
                      toPostalCode,
                      toCountry: "CA",
                      totalWeight,
                    };

                    const response = await dispatch(
                      shippingRates(payload, token)
                    );

                    if (response?.length > 0) {
                      setShippingCost(response[0]?.shipmentCost);
                    }
                  }
                }}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                size="small"
                disabled
              />
            </Grid>

            <CartFooter>
              <TotalSection>
                <Typography variant="h6" style={{ fontSize: 12 }}>
                  Sub Total
                </Typography>
                <Typography variant="h6" style={{ fontSize: 12 }}>
                  ${subTotal}
                </Typography>
              </TotalSection>
              <TotalSection>
                <Typography variant="h6" style={{ fontSize: 12 }}>
                  Shipping&nbsp;
                  {shippingCost === 0
                    ? "(Please enter your correct postal code to calculate the shipping price)"
                    : null}
                </Typography>
                <Typography variant="h6" style={{ fontSize: 12 }}>
                  ${shippingCost}
                </Typography>
              </TotalSection>
              <TotalSection>
                <Typography variant="h6" style={{ fontSize: 12 }}>
                  Tax(13%)
                </Typography>
                <Typography variant="h6" style={{ fontSize: 12 }}>
                  ${tax}
                </Typography>
              </TotalSection>
              <TotalSection>
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6">
                  ${subTotal + shippingCost + tax}
                </Typography>
              </TotalSection>{" "}
            </CartFooter>

            {!user?.YotiVerified && (
              <div
                style={{
                  color: "#ffa500",
                  background: "#ffa50033",
                  fontSize: "16px",
                  fontFamily: "Inter400",
                  borderRadius: "10px",
                  padding: "10px",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <p style={{ margin: 0 }}>
                  Please complete YOTI verification to place an order.
                  <br />
                  <br />
                  KYC (Know Your Customer). To start KYC&nbsp;
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => dispatch(updateField(SHOW_YOTI_MODAL))}
                  >
                    Click Here.
                  </span>
                </p>
              </div>
            )}
          </>

          {formData.name &&
            formData.address1 &&
            formData.city &&
            formData?.state &&
            formData.zipCode &&
            shippingCost >= 0 && (
              <div
                style={{
                  width: "100%",
                  border: "1px dashed #ccc",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    color: "gray",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  <LockIcon
                    aria-hidden="true"
                    className="mr-1.5 h-5 w-5 text-gray-400"
                  />
                  Stripe Secure Payment Gateway
                </p>

                <CardElement options={stripeOptions} />
                {/* <button type="submit" disabled={!stripe || loading}>
                {loading ? "Processing..." : "Pay"}
              </button> */}
                {error && <div style={{ color: "red" }}>{error}</div>}
              </div>
            )}
        </Grid>
      </StyledDialogContent>

      <StyledDialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handlePaymentSubmit}
          color="primary"
          variant="contained"
          style={{ backgroundColor: "#000", color: "#fff", fontWeight: "bold" }}
          disabled={
            !formData.name ||
            !formData.address1 ||
            !formData.city ||
            !formData?.state ||
            !formData.zipCode ||
            shippingCost === 0 ||
            !user?.YotiVerified
          }
        >
          PAY ${subTotal + shippingCost + tax}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ShippingAddressModal;
