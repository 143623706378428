import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlineIcon from "@material-ui/icons/Edit";
import Button from "components/Button";
import { Tooltip } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import {
  getCartItems,
  HIDE_CART_DRAWER,
  updateCartItem,
  deleteCartItem,
} from "actions/cart";
import CustomTextField from "components/TextField";
import { ROUTES } from "shared/constants/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ShippingAddressModal from "components/ShippingAddressModal";

import {
  ProductColor,
  ProductColorTitle,
  ProductColors,
} from "styles/components/ArtWork/product-tab";

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #eee;
`;

const EmptyCartMessage = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #666;
`;

const DrawerContent = styled.div`
  width: 50vw;
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CartItemsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
`;

const CartItem = styled.div`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #eee;
  gap: 16px;
`;

const ItemImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;

const ItemDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ItemActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CartFooter = styled.div`
  padding: 16px;
  border-top: 1px solid #eee;
  background: white;
`;

const TotalSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const CartDrawer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state?.cart);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [items, setItems] = useState([]);
  const [shippingModalOpen, setShippingModalOpen] = useState(false);

  const getItems = async () => {
    const data = await dispatch(getCartItems({ token }));
    setItems(data);
  };

  const updateItem = async (data) => {
    await dispatch(updateCartItem({ data, token }));
    await getItems({ token });
  };

  const handleRemoveItem = async (itemId) => {
    await dispatch(
      deleteCartItem({ data: { cartItemId: itemId, remove: true }, token })
    );
    await getItems({ token });
  };

  const calculateTotal = () => {
    let total = 0;

    for (let index = 0; index < items?.length; index++) {
      const element = items[index];
      const sizes = element?.product?.Sizes?.filter((s) => s?.qty > 0);

      for (let j = 0; j < sizes?.length; j++) {
        total +=
          (sizes[j]?.ColoredPrice + (sizes[j]?.DTGPrice || 0)) * sizes[j]?.qty;
      }
    }

    return total;
  };

  const calculateTax = () => {
    return calculateTotal() * 0.13;
  };

  const handleShippingSubmit = (shippingData) => {
    setShippingModalOpen(false);
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <>
      <Drawer
        anchor="right"
        open={cart.visibility}
        onClose={() => dispatch({ type: HIDE_CART_DRAWER })}
      >
        <DrawerContent>
          <DrawerHeader>
            <Typography variant="h6">Cart</Typography>
            <IconButton onClick={() => dispatch({ type: HIDE_CART_DRAWER })}>
              <CloseIcon />
            </IconButton>
          </DrawerHeader>

          {items?.length > 0 ? (
            <>
              <CartItemsContainer>
                {items?.length > 0 &&
                  items?.map((item, index) => (
                    <CartItem key={`${item.cartItemId}-${index}`}>
                      <ItemImage
                        src={item.colors[0]?.mockImagesResponse?.thumbImage}
                        alt={item.colors[0]?.mockImagesResponse?.thumbImage}
                      />
                      <ItemDetails>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="subtitle1">
                            {item.product?.Product?.Name}
                          </Typography>
                          <ItemActions>
                            <IconButton
                              size="small"
                              onClick={() => handleRemoveItem(item.cartItemId)}
                              color="secondary"
                            >
                              <DeleteOutlineIcon />
                            </IconButton>

                            <IconButton
                              size="small"
                              onClick={() => {
                                dispatch({ type: HIDE_CART_DRAWER });
                                history.push(
                                  `${ROUTES.AI_MOCK_UP_GENERATOR.LINK}/${item?.cartItemId}`
                                );
                              }}
                            >
                              <EditOutlineIcon />
                            </IconButton>
                          </ItemActions>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            justifyContent: "space-between",
                          }}
                        >
                          {item?.product?.Sizes?.map((s) => (
                            <div
                              key={s?.Name}
                              style={{ textAlign: "center", flex: 1 }}
                            >
                              {s?.Name}
                            </div>
                          ))}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            justifyContent: "space-between",
                          }}
                        >
                          {item?.product?.Sizes?.map((s) => (
                            <div
                              key={`${s?.Name}-${item?.cartItemId}`}
                              style={{ textAlign: "center", flex: 1 }}
                            >
                              $
                              {(
                                s?.ColoredPrice +
                                (s?.DTGPrice || s?.DURASOFTPrice || s?.EMBPrice)
                              ).toFixed(2)}
                            </div>
                          ))}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            justifyContent: "space-between",
                          }}
                        >
                          {item?.product?.Sizes?.map((s, i) => {
                            const key = `${s?.Name}-${item?.cartItemId}`;

                            return (
                              <div
                                key={key}
                                style={{ textAlign: "center", flex: 1 }}
                              >
                                <CustomTextField
                                  size="small"
                                  type="number"
                                  value={s?.qty || 0}
                                  style={{ width: 80 }}
                                  min={0}
                                  onBlur={() => {
                                    updateItem(item);
                                  }}
                                  onChange={(v) => {
                                    // const nonZeroQty =
                                    //   item?.product?.Sizes?.filter(
                                    //     (ss) => ss?.qty > 0
                                    //   )?.length;

                                    if (!v || Number(v) < 0) return;

                                    setItems(
                                      items?.map((ii, k) => {
                                        if (index !== k) {
                                          return { ...ii };
                                        }

                                        return {
                                          ...ii,
                                          product: {
                                            ...ii.product,
                                            Sizes: ii.product?.Sizes?.map(
                                              (q, j) => {
                                                if (i !== j) return { ...q };
                                                return { ...q, qty: Number(v) };
                                              }
                                            ),
                                          },
                                        };
                                      })
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>

                        <ProductColor style={{ border: 0 }}>
                          <ProductColorTitle
                            style={{ color: "rgba(0, 0, 0, 0.87)" }}
                          >
                            Selected Colors
                          </ProductColorTitle>
                          <ProductColors>
                            {item?.product?.Colors?.map(
                              (color) =>
                                !!item?.colors?.find(
                                  (c) => c?.ID === color?.ID
                                ) && (
                                  <Tooltip
                                    title={color?.Name}
                                    key={`Colors${color.ID}`}
                                  >
                                    <div
                                      style={{
                                        background: color?.HexCode,
                                        position: "relative",
                                      }}
                                    >
                                      {item?.colors?.length > 1 && (
                                        <CloseIcon
                                          onClick={() => {
                                            updateItem({
                                              ...item,
                                              colors: item.colors.filter(
                                                (c) => c?.ID !== color?.ID
                                              ),
                                            });
                                          }}
                                          style={{
                                            position: "absolute",
                                            top: "-6px",
                                            right: "-6px",
                                            color: "#fff",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            background: "#000",
                                            borderRadius: "50%",
                                            padding: "2px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Tooltip>
                                )
                            )}
                          </ProductColors>
                        </ProductColor>
                      </ItemDetails>
                    </CartItem>
                  ))}
              </CartItemsContainer>
              <CartFooter>
                <TotalSection>
                  <Typography variant="h6" style={{ fontSize: 12 }}>
                    Sub Total
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: 12 }}>
                    ${calculateTotal().toFixed(2)}
                  </Typography>
                </TotalSection>

                <TotalSection>
                  <Typography variant="h6" style={{ fontSize: 12 }}>
                    Shipping (It will be calculated at checkout)
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: 12 }}>
                    $0.00
                  </Typography>
                </TotalSection>
                <TotalSection>
                  <Typography variant="h6" style={{ fontSize: 12 }}>
                    Tax(13%)
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: 12 }}>
                    ${calculateTax().toFixed(2)}
                  </Typography>
                </TotalSection>

                <TotalSection>
                  <Typography variant="h6">Total</Typography>
                  <Typography variant="h6">
                    ${(calculateTotal() + calculateTax()).toFixed(2)}
                  </Typography>
                </TotalSection>
                <Button
                  fullWidth
                  onClick={() => setShippingModalOpen(true)}
                  title="Proceed to Checkout"
                  style={{
                    background: "#000",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                />
              </CartFooter>
            </>
          ) : (
            <EmptyCartMessage>
              <Typography variant="body1">
                Please add items to your cart
              </Typography>
            </EmptyCartMessage>
          )}
        </DrawerContent>
      </Drawer>

      {shippingModalOpen && (
        <ShippingAddressModal
          open={shippingModalOpen}
          onClose={() => setShippingModalOpen(false)}
          onSubmit={handleShippingSubmit}
          totalAmount={calculateTotal().toFixed(2)}
          items={items}
          subTotal={Number(calculateTotal().toFixed(2))}
          getItems={getItems}
        />
      )}
    </>
  );
};

export default CartDrawer;
