import ChooseProductRightBody from "pages/ai-mock-up-generator/ChooseProductRightBody";
import RenderGeneratedImages from "components/ArtWork/AiDesignPhases/PrevAIGeneratedImages";
import AiDesignsList from "pages/ai-mock-up-generator/AiDesignsList";
import Context, { Steps } from "pages/ai-mock-up-generator/context";
import Logo from "assets/images/shirtly-premium-logo.png";
import {
  Container,
  Wrapper,
  Header,
} from "styles/pages/ai-mock-up-generator/right-sidebar";
import Orders from "pages/orders";

const RightBody = ({ Body }) => {
  const { state } = Context();

  return (
    <Container>
      <Header>
        <img src={Logo} />
      </Header>
      <Wrapper id="right-body-wrapper">
        {!!Body && <Body />}

        {state?.step === Steps.Products && <ChooseProductRightBody />}

        {state?.step === Steps.AIDesginStudio && <RenderGeneratedImages />}

        {state?.step === Steps.MockUp && <AiDesignsList />}
      </Wrapper>
    </Container>
  );
};

export default RightBody;
