import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "actions/auth";
import Drawer from "components/Drawer";
import NotificationsDrawer from "components/NotificationsDrawer";
import CountryModal from "components/Header/CountryModal";
import Avatar from "@material-ui/core/Avatar";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";

import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuBarIcon from "assets/svg/MenuBarIcon";
import PersonIcon from "@material-ui/icons/Person";
import StyledBadge from "components/Header/StyledBadge";
import VLogo from "assets/images/shirtly-premium-logo.png";
import { getAllNotifications } from "actions/notifications";
import { SHOW_CART_DRAWER } from "actions/cart";
import {
  Nav,
  Wrapper,
  Logo,
  Menu,
  UserName,
  CurrencyMenu,
  MobileMenu,
  ProfileImage,
  ProfileName,
  NotificationIcon,
} from "styles/components/Header";
import CartDrawer from "components/CartDrawer";

const Header = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state?.currency?.value);
  const cart = useSelector((state) => state?.cart);
  const auth = useSelector((state) => state?.auth);
  const homeWebPage = useSelector((state) => state?.homeWebPage);
  const homeData = homeWebPage?.find((p) => p?.name === "Header") || {};
  const { components } = homeData;

  const [countryModalVisibility, updateCountryModalVisibility] =
    useState(false);
  const [drawerVisibility, updateDrawerVisibility] = useState(false);
  const toggleCountryModal = () =>
    updateCountryModalVisibility(!countryModalVisibility);
  const toggleDrawer = () => updateDrawerVisibility(!drawerVisibility);
  const profile = useSelector((state) => state?.auth?.profile);

  const [notificationDrawerVisibility, updateNotificationDrawerVisibility] =
    useState(false);
  const toggleNotificationDrawer = () =>
    updateNotificationDrawerVisibility(!notificationDrawerVisibility);

  const [notifications, setNotifications] = useState([]);

  const getNotificationsData = async () => {
    const data = await dispatch(getAllNotifications());
    setNotifications(() => data);
  };

  const { Image } = components || {};

  const ipUser = auth?.profile?.IPUser;

  // useEffect(() => {
  //   getNotificationsData();
  // }, [dispatch]);

  return (
    <Fragment>
      <Nav>
        <Wrapper>
          {!ipUser && (
            <MenuBarIcon onClick={toggleDrawer} className="menu-bar-icon" />
          )}
          {Image && <Logo src={VLogo} alt="logo" />}
        </Wrapper>
        <Wrapper>
          <Menu>
            {/* <NotificationIcon onClick={toggleNotificationDrawer}>
              <StyledBadge
                badgeContent={
                  notifications?.length ? `${notifications?.length}+` : "0"
                }
                color="secondary"
              >
                <Avatar>
                  <NotificationsActiveIcon className="notification-icon" />
                </Avatar>
              </StyledBadge>
            </NotificationIcon> */}
            {!ipUser && (
              <CurrencyMenu onClick={toggleCountryModal}>
                {currency === "USD" ? "US" : "CA"} Dollar
                <KeyboardArrowDownIcon style={{ color: "#ccc" }} />
              </CurrencyMenu>
            )}
            <UserName>
              <ProfileImage>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ProfileImage>
              <ProfileName>
                <p>Hello</p>
                <p className="full-name">{profile?.FullName}</p>
              </ProfileName>
            </UserName>
            <Badge
              badgeContent={cart?.count}
              showZero
              color="secondary"
              style={{ marginRight: "20px" }}
              onClick={() => {
                dispatch({ type: SHOW_CART_DRAWER });
              }}
            >
              <ShoppingCartIcon style={{ color: "#0009", cursor: "pointer" }} />
            </Badge>

            <Avatar>
              <PowerSettingsNewIcon
                onClick={() => {
                  dispatch(logout());
                }}
              />
            </Avatar>
          </Menu>
          <MobileMenu>
            <ProfileName>
              <p>Hello</p>
              <p className="full-name">{profile?.FullName}</p>
            </ProfileName>
          </MobileMenu>
        </Wrapper>
      </Nav>
      {countryModalVisibility && (
        <CountryModal
          open={countryModalVisibility}
          onClose={toggleCountryModal}
        />
      )}

      {drawerVisibility && (
        <Drawer open={drawerVisibility} toggle={toggleDrawer} />
      )}

      <NotificationsDrawer
        open={notificationDrawerVisibility}
        toggle={toggleNotificationDrawer}
      />

      {cart.visibility && <CartDrawer />}
    </Fragment>
  );
};

export default Header;
