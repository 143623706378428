const columns = [
  {
    id: "orderNumber",
    label: "Order#",
    minWidth: 170,
  },

  {
    id: "BuyerName",
    label: "Buyer",
    minWidth: 170,
  },
  {
    id: "OrderDate",
    label: "Order Date",
    minWidth: 170,
  },
  {
    id: "TotalCost",
    label: "Total Cost",
    minWidth: 170,
  },
  {
    id: "TrackingNumber",
    label: "Tracking Number",
    minWidth: 170,
  },
];

export { columns };
