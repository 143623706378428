import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "actions/auth";
import SubHeader from "components/SubHeader";
import GeneralInfoForm from "pages/account-settings/GeneralInfoForm";
import { Container } from "styles/pages/account-setting";
import PasswordForm from "pages/account-settings/PasswordForm";
import AutomationForm from "pages/account-settings/AutomationForm";
import PaymentOptions from "pages/account-settings/PaymentOptions";
import Tabs from "components/Tabs";
import CryptoJS from "crypto-js";
// import ResaleCertificate from "pages/account-settings/ResaleCertificate";
import {
  getUserInformation,
  updateUserInformation,
  deactivateUser,
  updatePassword,
  automationToggle,
  deleteUnSyncedOrders,
} from "actions/user";
import { getUserAllSyncedProducts } from "actions/products";
import { getAllCards, removeCard, saveCard } from "actions/stripe";
import { getCountries } from "actions/countries";

const AccountSettings = () => {
  const dispatch = useDispatch();
  const emailId = useSelector((state) => state?.auth?.profile?.emailid);
  const countries = useSelector((state) => state?.countries?.data);
  const [cards, setCards] = useState(undefined);
  const [syncedProducts, setSyncedProducts] = useState(undefined);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!user) {
        const request = await dispatch(getUserInformation(emailId));
        if (request?.error) {
          return;
        }
        setUser(request);
      }
    };
    fetchUserDetails();
  }, [dispatch, emailId, user]);

  useEffect(() => {
    const loadCountries = async () => {
      if (!countries?.length) {
        dispatch(getCountries());
      }
    };
    loadCountries();
  }, [dispatch, countries]);

  const fetchCards = async () => {
    const response = await dispatch(
      getAllCards({
        user: emailId,
      })
    );

    if (response?.error) {
      return;
    }

    setCards(() => response);

    if (response?.length === 0) {
      const data = {
        user: user?.User?.Email,
        automation: false,
      };
      await dispatch(automationToggle(data));
      setUser(undefined);
    }
  };

  const fetchUserSyncedProducts = async () => {
    const response = await dispatch(
      getUserAllSyncedProducts({
        emailid: emailId,
      })
    );

    if (response?.error) {
      return;
    }

    setSyncedProducts(() => response);
  };

  return (
    <Container>
      {/* {user && <SubHeader user={user} />} */}
      <SubHeader
        title="Manage Account"
        subTitle={`You're logged in as ${emailId}`}
      />
      <Tabs
        onTabChange={async (index) => {
          if (index === 3 || index === 2) {
            await fetchCards();
            await fetchUserSyncedProducts();
          }
        }}
        tabs={[
          {
            title: "Update Profile",
            children: user && countries && (
              <GeneralInfoForm
                user={user}
                countries={countries}
                update={async (data) => {
                  const request = await dispatch(updateUserInformation(data));

                  if (request?.error) {
                    return;
                  }
                }}
              />
            ),
          },
          {
            title: "Change Password",
            children: (
              <PasswordForm
                user={user}
                updatePassword={async (payload) => {
                  const request = await dispatch(updatePassword(payload));

                  if (request?.error) {
                    return;
                  }
                }}
                deactivate={async () => {
                  const data = {
                    userId: user?.User?.Email,
                    password: user?.User?.PasswordHash,
                  };

                  const request = await dispatch(deactivateUser(data));

                  if (request?.error) {
                    return;
                  }
                  dispatch(logout());
                }}
              />
            ),
          },
          {
            title: "Automation",
            children: (
              <AutomationForm
                cards={cards}
                syncedProducts={syncedProducts}
                automation={user?.automation}
                deleteUnSyncedOrders={user?.deleteUnSyncedOrders}
                setAutomation={(automation) =>
                  setUser((prevState) => ({ ...prevState, automation }))
                }
                handleChange={async (automation) => {
                  const data = {
                    user: user?.User?.Email,
                    automation,
                  };
                  await dispatch(automationToggle(data));
                  setUser(undefined);
                }}
                handleDeleteUnSyncedOrdersChange={async (v) => {
                  const data = {
                    user: user?.User?.Email,
                    deleteUnSyncedOrders: v,
                  };
                  await dispatch(deleteUnSyncedOrders(data));
                  setUser(undefined);
                }}
              />
            ),
          },
          {
            title: "Payment Options",
            children: (
              <PaymentOptions
                cards={cards}
                removeCard={async (card) => {
                  const request = await dispatch(
                    removeCard({
                      user: emailId,
                      cardNumber: card?.cardNumber,
                    })
                  );

                  if (request?.error) {
                    return;
                  }

                  await fetchCards();
                }}
                saveCard={async (card) => {
                  const cardDetails = {
                    cardNumber: CryptoJS.AES.encrypt(
                      card.cardNumber,
                      "shirtly"
                    ).toString(),
                    cardExpMonth: CryptoJS.AES.encrypt(
                      card.cardExpMonth,
                      "shirtly"
                    ).toString(),
                    cardExpYear: CryptoJS.AES.encrypt(
                      card.cardExpYear,
                      "shirtly"
                    ).toString(),
                    cvv: CryptoJS.AES.encrypt(card.cvv, "shirtly").toString(),
                  };

                  await dispatch(saveCard({ cardDetails, user: emailId }));
                  await fetchCards();
                }}
              />
            ),
          },
          // {
          //   title: 'Re-sale Certificate',
          //   children: (
          //     <ResaleCertificate
          //       user={user}
          //       refresh={() => setUser(undefined)}
          //     />
          //   ),
          // },
        ]}
      />
    </Container>
  );
};

export default AccountSettings;
