import { Tooltip } from "@material-ui/core";
import CustomCheckbox from "components/CheckBox";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { Steps } from "pages/ai-mock-up-generator/context";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import getRandomColor from "utils/getRandomColor";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "components/TextField";
import { default as ImageComponent } from "components/Image";
import {
  Container,
  ProductName,
  ChangeProduct,
  ProductColor,
  ProductColorTitle,
  ProductColors,
  StyledTextField,
} from "styles/components/ArtWork/product-tab";

import {
  ProductColorWrapper,
  GeneratedMockups,
} from "styles/pages/my-stores/products/productCreator";

const ProductTab = ({ state, setState, product, orderLine, setOrderLine }) => {
  const printTypes = [
    ...product?.AdditionalTechniquesArtSettings?.map((p) => p?.name)?.filter(
      (p) => p !== "DTGPlus" && p !== "Plus"
    ),
    product?.Product?.PrintType,
  ];

  return (
    <Container>
      <ProductName>
        <div>{state?.product?.Product?.Name}</div>
        <ChangeProduct
          title="Change Product"
          endIcon={<SyncAltIcon />}
          onClick={() => setState({ ...state, step: Steps.Products })}
        />
      </ProductName>

      <ProductColor>
        <ProductColorTitle>Product Colors</ProductColorTitle>
        <ProductColors>
          {state?.product?.Colors?.map((color, index) => (
            <Tooltip title={color?.Name} key={`Colors${color.ID}`}>
              <div
                style={{
                  background: color?.HexCode,
                }}
                onClick={() => {
                  setState((p) => ({
                    ...p,
                    artwork: {
                      ...p.artwork,
                      printAreas: p?.artwork?.printAreas?.map((p) => ({
                        ...p,
                        printAreaMarketingImage:
                          p?.printAreaName === "Front"
                            ? color?.Image
                            : color[`${p?.printAreaName}Image`],
                      })),
                    },
                    product: {
                      ...p.product,
                      Colors: state?.product?.Colors?.map((c, j) => ({
                        ...c,
                        selected: index === j,
                      })),
                    },
                    colors: !!state?.colors?.find((c) => c?.ID === color?.ID)
                      ? state.colors?.length > 1
                        ? state?.colors?.filter((c) => c?.ID !== color?.ID)
                        : state.colors
                      : [...state.colors, { ...color }],
                  }));
                }}
              >
                {!!state?.colors?.find((c) => c?.ID === color?.ID) && (
                  <DoneOutlineOutlinedIcon
                    style={{ color: getRandomColor() }}
                  />
                )}
              </div>
            </Tooltip>
          ))}
        </ProductColors>
      </ProductColor>

      <ProductColor>
        <ProductColorTitle>Product Decorating Techniques</ProductColorTitle>

        <div style={{ color: "#fff" }}>
          {printTypes?.map((p) => (
            <div key={p}>
              <CustomCheckbox
                label={p}
                checked={orderLine?.PrintType === p}
                onChange={() => setOrderLine({ ...orderLine, PrintType: p })}
                color="#fff"
              />
            </div>
          ))}
        </div>

        {/* <div>
          {printType && (
            <CustomCheckbox
              label={printType}
              checked={
                orderLine?.printAreas?.find((p) => p?.selected)?.printType ===
                printType
              }
              onChange={() => {}}
            />
          )}
        </div> */}
      </ProductColor>

      <ProductColor>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ProductColorTitle>Product Sizes</ProductColorTitle>
          <ProductColorTitle>Qty</ProductColorTitle>
        </div>

        <div>
          {state?.product?.Sizes?.map((s, i) => (
            <div
              key={s?.ID}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <b style={{ color: "#fff" }}>{s?.Name}</b>

              <StyledTextField>
                <TextField
                  size="small"
                  type="number"
                  value={s?.qty || 0}
                  style={{ width: 80 }}
                  min={0}
                  onChange={(v) =>
                    setState({
                      ...state,
                      product: {
                        ...state.product,
                        Sizes: state.product?.Sizes?.map((q, j) => {
                          if (i !== j) return { ...q };

                          return { ...q, qty: Number(v) };
                        }),
                      },
                    })
                  }
                />
              </StyledTextField>
            </div>
          ))}
        </div>
      </ProductColor>

      <ProductColor>
        <ProductColorTitle>Selected Colors</ProductColorTitle>

        <ProductColorWrapper>
          <GeneratedMockups style={{ margin: 0 }}>
            {state?.colors?.map((color, index) => (
              <span className="cross">
                <ImageComponent
                  src={color?.Image}
                  imageStyle={{ width: "100px" }}
                />
                {state?.colors?.length > 1 && (
                  <CancelIcon
                    className="download-icon"
                    style={{ top: 4, right: 4 }}
                    onClick={() => {
                      setState({
                        ...state,
                        colors: state?.colors?.filter((c, j) => index !== j),
                      });
                    }}
                  />
                )}
              </span>
            ))}
          </GeneratedMockups>
        </ProductColorWrapper>
      </ProductColor>
    </Container>
  );
};

export default ProductTab;
