const row = (
  orderNumber,

  BuyerName,
  OrderDate,
  TotalCost,
  TrackingNumber
) => {
  return {
    orderNumber,

    BuyerName,
    OrderDate,
    TotalCost,
    TrackingNumber,
  };
};

export { row };
