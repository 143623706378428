import styled from "styled-components";
import Button from "components/Button";

export const Container = styled.div`
  width: 100%;
`;

export const ProductName = styled.h3`
  margin: 0;

  padding: 14px 20px;
  text-align: left;
  font-size: 15px;
  color: #fff;
`;

export const ChangeProduct = styled(Button)`
  &.MuiButton-root {
    margin-top: 10px;
  }

  &.MuiButton-contained {
    background-color: #ddd;
    font-size: 12px;
    color: #3f4652;
  }

  .MuiButton-endIcon {
    display: inherit;
    margin-left: 5px;
    margin-bottom: 1px;
  }
`;

export const ProductColor = styled.div`
  border-top: 1px solid #515965;
  border-bottom: 1px solid #515965;
  padding: 14px;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const ProductColorTitle = styled.div`
  color: #fff;
  font-size: 14px;
`;

export const ProductColors = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  div {
    width: 38px;
    height: 38px;
    border-radius: 38px;
    border: 2px solid rgba(221, 221, 221, 0.5);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledTextField = styled.div`
  .MuiFormLabel-root,
  .MuiInputBase-input {
    color: #fff;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
  }

  .MuiInputBase-input {
    height: 10px;
  }
`;
