import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Header = styled.div`
  width: 70px;
  height: 100vh;
  background-color: #272c33;
`;

export const HeaderOption = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  /* width: 85px; */
  background-color: ${({ selected }) => (selected ? "#3f4652" : "#272c33")};
  /* color: ${({ selected }) => (selected ? "#fff" : "#3a3a3c")}; */
  color: #fff;
  padding: 15px 0;

  :hover {
    /* background-color: #3f4652; */
    color: #fff;
  }

  .title {
    margin: 0;
    font-size: 10px;
    font-family: "Inter600";
    text-align: center;
  }

  svg {
    font-size: 20px;
    margin-top: 5px;
  }
`;

export const Body = styled.div`
  display: flex;
  height: 100vh;
  background: #3f4652;
  overflow: auto;
  width: 350px;
`;
