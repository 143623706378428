import { post, get } from "@axios";
import { showLoader, hideLoader } from "actions/loader";
import { requests } from "shared/requests";
import { SHOW_ERROR_MODAL } from "actions/errorModal";
import { logout } from "actions/auth";

const getDesigns = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let designs = await post({ url: requests.getDesigns, data });
      if (designs?.resources?.length) {
        for (let i = 0; i < designs?.resources?.length; i++) {
          designs.resources[i].secure_url = designs?.resources[
            i
          ].secure_url.replace(".pdf", ".png");
        }
      }
      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const searchDesigns = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const designs = await post({ url: requests.searchDesigns, data });
      if (designs?.resources?.length) {
        for (let i = 0; i < designs?.resources?.length; i++) {
          designs.resources[i].secure_url = designs?.resources[
            i
          ].secure_url.replace(".pdf", ".png");
        }
      }
      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getDesignsByPublicId = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let designs = await post({ url: requests.getDesignsByPublicId, data });

      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getBackgroundBurnDesigns = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let designs = await post({
        url: requests.getBackgroundBurnDesigns,
        data,
      });
      if (designs?.resources?.length) {
        for (let i = 0; i < designs?.resources?.length; i++) {
          designs.resources[i].secure_url = designs?.resources[
            i
          ].secure_url.replace(".pdf", ".png");
        }
      }
      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getPlusArtFiles = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let designs = await post({
        url: requests.getPlusArtFiles,
        data,
      });
      if (designs?.resources?.length) {
        for (let i = 0; i < designs?.resources?.length; i++) {
          designs.resources[i].secure_url = designs?.resources[
            i
          ].secure_url.replace(".pdf", ".png");
        }
      }
      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getAdvancedDesigns = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let designs = await post({
        url: requests.getAdvancedDesigns,
        data,
      });

      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const deleteAdvanceArtFiles = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let designs = await post({
        url: requests.deleteAdvanceArtFiles,
        data,
      });

      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getMocks = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const response = await post({ url: requests.getMocks, data });

      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const clearMocks = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const response = await post({ url: requests.clearMocks, data });

      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const clearArtWork = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const designs = await post({ url: requests.clearArtWork, data });
      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getPreDesigns = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const designs = await post({ url: requests.getPreDesigns, data });
      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getPhraseDesigns = (cursor) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const designs = await post({
        url: requests.GetPhraseDesigns,
        data: { tag: "ShirtlyPhrases", next_cursor: cursor },
      });
      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const allCustomGraphicsUsers = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const designs = await get({
        url: requests.allCustomGraphicsUsers,
      });
      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getAllCustomGraphics = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const designs = await post({
        url: requests.getAllCustomGraphics,
        data,
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());

      if (designs?.tokenExpired) {
        dispatch(logout());
      }

      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getCustomGraphicsTags = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const designs = await post({
        url: requests.getCustomGraphicsTags,
        data,
      });
      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getCustomGraphics = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const designs = await post({
        url: requests.getCustomGraphics,
        data,
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());

      if (designs?.tokenExpired) {
        dispatch(logout());
      }

      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getAIDesigns = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let designs = await post({ url: requests.getAIDesigns, data });
      // if (designs?.resources?.length) {
      //   for (let i = 0; i < designs?.resources?.length; i++) {
      //     designs.resources[i].secure_url = designs?.resources[
      //       i
      //     ].secure_url.replace(".pdf", ".png");
      //   }
      // }
      dispatch(hideLoader());
      return designs;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

export {
  getDesigns,
  clearArtWork,
  getPreDesigns,
  getPhraseDesigns,
  getMocks,
  clearMocks,
  getBackgroundBurnDesigns,
  getPlusArtFiles,
  getDesignsByPublicId,
  searchDesigns,
  getAdvancedDesigns,
  deleteAdvanceArtFiles,
  allCustomGraphicsUsers,
  getAllCustomGraphics,
  getCustomGraphicsTags,
  getCustomGraphics,
  getAIDesigns,
};
