import GeneratedMocks from "components/ArtWork/GeneratedMocks";

const MocksLibrary = () => (
  <div style={{ width: "100%" }}>
    <h1
      style={{
        margin: "0px",
        fontSize: "15px",
        color: "rgb(255, 255, 255)",
        fontWeight: "100",
        fontFamily: "Inter800",
        padding: "14px 20px",
      }}
    >
      Mocks Library
    </h1>
    <GeneratedMocks />
  </div>
);

export default MocksLibrary;
