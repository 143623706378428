import Sidebar from "pages/ai-mock-up-generator/Sidebar";
import ErrorMessage from "components/ErrorMessage";
import FooterLogo from "pages/ai-mock-up-generator/FooterLogo";
import HeaderLogo from "pages/ai-mock-up-generator/HeaderLogo";
import Welcome from "pages/ai-mock-up-generator/Welcome";
import Splash from "pages/ai-mock-up-generator/Splash";
import BeginWithAiAssistant from "pages/ai-mock-up-generator/BeginWithAiAssistant";
import SelectAiAssistant from "pages/ai-mock-up-generator/SelectAiAssistant";
import AIGeneratedImagesRight from "components/ArtWork/AIGeneratedImagesRight";
import RenderGeneratedImages from "components/ArtWork/AiDesignPhases/PrevAIGeneratedImages";
import ChooseProduct from "pages/ai-mock-up-generator/ChooseProduct";
import ArtworkTool from "pages/ai-mock-up-generator/ArtworkTool";
import RightBody from "pages/ai-mock-up-generator/RightBody";
import MockLibrary from "pages/ai-mock-up-generator/MockLibrary";
import Context, { Provider, Steps } from "pages/ai-mock-up-generator/context";
import { Provider as AIProvider } from "components/ArtWork/aiGeneratedImagesContext";
import Logo from "assets/images/shirtly-premium-logo.png";
import { Container, RightSection } from "styles/pages/ai-mock-up-generator";
import CheckIcon from "@material-ui/icons/Check";
import {
  Container as RContainer,
  Wrapper as RWrapper,
  Header as RHeader,
} from "styles/pages/ai-mock-up-generator/right-sidebar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { getCartItems } from "actions/cart";
import { useEffect, useState } from "react";
import {
  getUserInformation,
  yotiCheckStatus,
  yotiVerificationComplete,
} from "actions/user";
import { ApprovedKyc } from "styles/pages/dashboard";
import { updateField } from "actions/common";
import { SHOW_YOTI_MODAL } from "actions/yoti";
import Orders from "pages/orders";

const AiDesignStudio = () => (
  <>
    <RightSection>
      <AIGeneratedImagesRight />
    </RightSection>

    <RContainer>
      <RHeader>
        <img src={Logo} />
      </RHeader>
      <RWrapper>
        <RenderGeneratedImages />
      </RWrapper>
    </RContainer>
  </>
);

const Wrapper = () => {
  const [yotiModal, setYotiModal] = useState();
  const [user, setUser] = useState(undefined);
  const dispatch = useDispatch();
  const { cartItemId } = useParams();
  const token = useSelector((state) => state?.auth?.profile?.token);

  const emailId = useSelector((state) => state?.auth?.profile?.emailid);
  const { state, setState } = Context();

  const getItems = async () => {
    const items = await dispatch(getCartItems({ token }));

    const item = items?.find((item) => item.cartItemId == cartItemId);

    if (item) {
      setState(item);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!user) {
        const request = await dispatch(getUserInformation(emailId));
        if (request?.error) {
          return;
        }
        setUser(request);
      }
    };
    fetchUserDetails();
  }, [dispatch, emailId, user]);

  useEffect(() => {
    if (cartItemId && token) {
      getItems();
    }
  }, [cartItemId, token]);

  const renderKYC = () => {
    return user?.YotiVerified ? (
      user?.YotiVerificationStatus === "Pending" ? (
        <ErrorMessage className="warning">
          <p>
            <a
              rel="noreferrer"
              href="https://www.yoti.com/blog/our-approach-to-security-and-privacy/"
              target="_blank"
            >
              FAQ’s and Security using Yoti
            </a>
            <br />
            <br />
            Your submited documents is under review by YOTI.&nbsp;
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={async () => {
                const data = await dispatch(
                  yotiCheckStatus({
                    user: emailId,
                    sessionId: user?.YotiSessionData?.sessionId,
                  })
                );

                const isVerified = !!(
                  data?.find((o) => o?.state === "DONE") &&
                  data?.find(
                    (o) => o?.report?.recommendation?.value === "APPROVE"
                  )
                );

                const isRejected = !!(
                  data?.find((o) => o?.state === "DONE") &&
                  data?.find(
                    (o) => o?.report?.recommendation?.value === "REJECT"
                  )
                );

                const isNotAvailable = !!(
                  data?.find((o) => o?.state === "DONE") &&
                  data?.find(
                    (o) => o?.report?.recommendation?.value === "NOT_AVAILABLE"
                  )
                );

                if (isVerified) {
                  await dispatch(
                    yotiVerificationComplete({
                      user: emailId,
                      YotiVerified: true,
                      YotiVerificationStatus: "Approve",
                    })
                  );
                  setUser(undefined);
                } else if (isRejected || isNotAvailable) {
                  await dispatch(
                    yotiVerificationComplete({
                      user: emailId,
                      YotiVerified: false,
                      YotiVerificationStatus: "Reject",
                    })
                  );
                  setUser(undefined);
                }

                setYotiModal(data);
              }}
            >
              Check Status
            </span>
            <br />
            Verification take upto 10-15 minutes to complete.
          </p>
        </ErrorMessage>
      ) : user?.YotiVerificationStatus === "Approve" ? (
        <ErrorMessage className="success">
          <ApprovedKyc>
            <CheckIcon />
            <b>KYC complete. Submitted documents has been approved.</b>
          </ApprovedKyc>
        </ErrorMessage>
      ) : null
    ) : (
      <ErrorMessage className="warning">
        <p>
          <a
            rel="noreferrer"
            href="https://www.yoti.com/blog/our-approach-to-security-and-privacy/"
            target="_blank"
          >
            FAQ’s and Security using Yoti
          </a>
          <br />
          <br />
          KYC (Know Your Customer). To start KYC&nbsp;
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => dispatch(updateField(SHOW_YOTI_MODAL))}
          >
            Click Here.
          </span>
        </p>
      </ErrorMessage>
    );
  };

  const hideSideBar =
    state.step === Steps.Welcome ||
    state.step === Steps.BeginWithAiAssitant ||
    state.step === Steps.SelectAiAssistant;
  const showRightBody =
    state.step !== Steps.AIDesginStudio &&
    state.step !== Steps.Welcome &&
    state.step !== Steps.BeginWithAiAssitant &&
    state.step !== Steps.SelectAiAssistant &&
    state.step !== Steps.MockLibrary &&
    state.step !== Steps.Products &&
    state.step !== Steps.Order;

  if (state?.step === Steps.Splash && !cartItemId) {
    return (
      <AIProvider>
        <Splash />
      </AIProvider>
    );
  }

  return (
    <>
      <div style={{ margin: "0 20px" }}>{renderKYC()}</div>

      <Container hideSideBar={hideSideBar}>
        {(state.step === Steps.Welcome ||
          state.step === Steps.BeginWithAiAssitant ||
          state.step === Steps.SelectAiAssistant ||
          state.step === Steps.MockLibrary) && <HeaderLogo />}
        {!hideSideBar && <Sidebar />}

        {showRightBody && (
          <RightSection>
            {state.step === Steps.MockUp && <ArtworkTool />}
          </RightSection>
        )}

        {state.step === Steps.Products && (
          <AIProvider>
            <ChooseProduct />
          </AIProvider>
        )}

        {state.step === Steps.Order && (
          <div style={{ width: "calc(-400px + 100vw)" }}>
            <Orders />
          </div>
        )}

        {state.step === Steps.SelectAiAssistant && (
          <AIProvider>
            <SelectAiAssistant />
          </AIProvider>
        )}

        {state.step === Steps.Welcome && (
          <AIProvider>
            <Welcome />
          </AIProvider>
        )}

        {state.step === Steps.BeginWithAiAssitant && (
          <AIProvider>
            <BeginWithAiAssistant />
          </AIProvider>
        )}

        {state.step === Steps.AIDesginStudio && (
          <AIProvider>
            <AiDesignStudio />
          </AIProvider>
        )}

        {state?.step === Steps.MockLibrary && (
          <div style={{ width: "calc(-400px + 100vw)" }}>
            <MockLibrary />
          </div>
        )}

        {showRightBody && <RightBody />}

        <FooterLogo />
      </Container>
    </>
  );
};

const AIMockUpGenerator = () => (
  <Provider>
    <Wrapper />
  </Provider>
);

export default AIMockUpGenerator;
