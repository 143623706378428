import { post } from "@axios";
import { requests } from "shared/requests";
import { showLoader, hideLoader } from "actions/loader";
import { logout } from "actions/auth";

const SET_HOME_WEB_PAGE = "SET_HOME_WEB_PAGE";

const getAllWebPages = (token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.allWebPages, token });
      dispatch(hideLoader());

      if (request?.tokenExpired) {
        dispatch(logout());
      }

      return request;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const getWebPage = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.webPage, data });
      dispatch(hideLoader());

      if (request?.tokenExpired) {
        dispatch(logout());
      }

      if (data?.page === "LandingPage") {
        dispatch({ type: SET_HOME_WEB_PAGE, payload: request });
      }

      return request;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const updateSection = ({ data, token }) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.updateSection, data, token });
      dispatch(hideLoader());

      if (request?.tokenExpired) {
        dispatch(logout());
      }

      return request;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const addDashboardSection = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.addDashboardSection, data });
      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const updateDashboardSection = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.updateDashboardSection,
        data,
      });
      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const deleteDashboardSection = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.deleteDashboardSection,
        data,
      });
      dispatch(hideLoader());

      return request;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

export {
  getAllWebPages,
  getWebPage,
  updateSection,
  addDashboardSection,
  SET_HOME_WEB_PAGE,
  updateDashboardSection,
  deleteDashboardSection,
};
