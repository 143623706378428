const generateShortUuid = () => {
  // const fullUuid = crypto.randomUUID();
  // const uuidBytes = Uint8Array.from(fullUuid.replace(/-/g, ""), (c) =>
  //   c.charCodeAt(0)
  // );
  // const base64 = btoa(String.fromCharCode.apply(null, uuidBytes));
  // return base64.slice(0, length);
  const timestamp = Date.now().toString().slice(-6);
  const randomPart = Math.floor(Math.random() * 100)
    .toString()
    .padStart(2, "0");
  return `${timestamp}${randomPart}`;
};

export { generateShortUuid };
