import * as a from "actions/aiTokens";

const initial = {
  aiTokens: 0,
};

const reducer = (state = initial, action = null) => {
  switch (action.type) {
    case a.SET_AI_TOKENS:
      return {
        ...state,
        aiTokens: action.aiTokens,
      };

    default:
      return state;
  }
};

export default reducer;
