import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { HIDE_ERROR_MODAL } from "actions/errorModal";
import ServerErrorImage from "assets/images/server-error.gif";
import CloseIcon from "@material-ui/icons/Close";

export default function Modal() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state?.errorModal?.visibility);
  const handleClose = () => dispatch({ type: HIDE_ERROR_MODAL });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ paddingBottom: 0 }}>
        <CloseIcon
          style={{ float: "right", cursor: "pointer", color: "#a1a1a1" }}
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        <img
          src={ServerErrorImage}
          alt="ServerError"
          style={{ display: "block", margin: "0 auto", width: 150 }}
        />
        <DialogContentText style={{ fontWeight: "bold", textAlign: "center" }}>
          Server error, please try again after sometime.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
