import React from "react";
import moment from "moment";
import { imagesBaseURL } from "shared/requests";
import { row } from "pages/orders/table/row";
import USAFlag from "assets/images/usa-flag.png";
import CADFlag from "assets/images/canada-flag.png";
import EUFlag from "assets/images/eu-flag.png";
import AUFlag from "assets/images/aus-flag.jpeg";
import Tooltip from "@material-ui/core/Tooltip";
import BigCommerceLogo from "assets/images/bigcommerce.svg";
import BigCartelLogo from "assets/images/big-cartel-coloured-logo.jpeg";
import WooCommerceLogo from "assets/svg/WooCommerceLogo";
import { USD_TO_CAD_CONVERSION_RATE } from "constants/currency";
import {
  Shipped,
  Cancelled,
  Pending,
  AwaitingPayment,
  InProduction,
} from "styles/pages/orders/table";

const statusButton = (row) => {
  if (row.Status === "Shipped") {
    return <Shipped>SHIPPED</Shipped>;
  } else if (row.Status === "Cancelled") {
    return <Cancelled>CANCELLED</Cancelled>;
  } else if (row.Status === "PendingDetails") {
    return <Pending>PENDING</Pending>;
  } else if (row.Status === "AwaitingPayment") {
    return <AwaitingPayment>Awaiting Payment</AwaitingPayment>;
  } else if (row.Status === "InProduction") {
    return <InProduction>In Production</InProduction>;
  }
};

const rows = (data, currency) => {
  let items = [];

  console.log(data);

  for (let i = 0; i < data.length; i++) {
    if (data[i]) {
      const {
        orderNumber,
        shippingAddress,
        createdAt,
        TrackingNumber,
        paymentDetails,
      } = data[i];

      items.push(
        row(
          orderNumber,
          shippingAddress?.name,
          moment.unix(createdAt).format("MMM DD, YYYY"),
          `$${paymentDetails?.orderTotal}`,
          // currency === "USD"
          //   ? `$${TotalCost ? TotalCost.toFixed(2) : 0.0}`
          //   : (Number(TotalCost || 0) * USD_TO_CAD_CONVERSION_RATE).toFixed(2),
          TrackingNumber || "NA"
        )
      );
    }
  }
  return items;
};

export { rows };
